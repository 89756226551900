import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { AuthenticationService } from 'src/app/common/services/authentication.service';

@Component({
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss']
})
export class LoginSsoComponent implements OnInit {

  loginProgress = true;
  params: any;
  fetchingCard: boolean = false;

  constructor(
    public GlobalConstants: GlobalConstants,
    public route: ActivatedRoute,
    public router: Router,
    private authService: AuthenticationService,
    private _modalService: BsModalService
  ) {
    this.processData();

  }
  ngOnInit() {}

  processData() {
    this.removeLocalStorage();
    let qparams = this.route.snapshot.queryParams.data ? JSON.parse(atob(this.route.snapshot.queryParams.data)) : {};
    let dataParam = {
      tk: qparams.token ? qparams.token : null
    }
    sessionStorage.setItem('razorpayparams', JSON.stringify(dataParam));
    this.authService.processDataQuery(dataParam, this.route?.snapshot?.queryParams?.targeturl);
  }
  
  removeLocalStorage() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('expire_at');
    localStorage.removeItem('userDetails');
    sessionStorage.clear();
  }
}
