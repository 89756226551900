import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-plus-minus',
  templateUrl: './plus-minus.component.html',
  styleUrls: ['./plus-minus.component.scss']
})
export class PlusMinusComponent implements OnInit {
  @Input() num;
  @Output() changeEvent = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  update(type){
    this.changeEvent.emit(type);
  }
}
