import { ToastrService } from 'ngx-toastr';
import { AirportService, vipServiceBookingTyp } from 'src/app/common/services/airport.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-service-confirmation',
  templateUrl: './service-confirmation.component.html',
  styleUrls: ['./service-confirmation.component.scss']
})
export class ServiceConfirmationComponent implements OnInit, OnDestroy {

  bookingId;
  bookingDetails: vipServiceBookingTyp = {
    requestedServices : [],
    id: '',
    airportDetails : {},
    users: [],
    payment_details: {},
    journey_type: ''
  };
  isConfirming: boolean = false;

  @Input() activeStep;
  @Output() nextStep = new EventEmitter<any>();

  constructor(
    private route: ActivatedRoute,
    private airportService: AirportService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.paramMap.get('id') || sessionStorage.getItem('booking_id');
    this.airportService.getBookingById(this.bookingId).then((res: any) => {
      if(res.data?.attributes) {
        this.bookingDetails.id = res.data.attributes.unique_id;
        this.bookingDetails.users = res.data.attributes.details.attributes.user_pack
        if(res.data.attributes?.details?.attributes?.services_response?.data && Array.isArray(res.data.attributes?.details?.attributes?.services_response?.data) && res.data.attributes?.details?.attributes?.services_response?.data?.length > 0) {
          this.bookingDetails.airportDetails = res.data.attributes.details.attributes.services_response.data.find(x => {
            return x.iata == res.data.attributes.details.attributes.service_airport_code
          })
        }
        this.bookingDetails.journey_type = res.data.attributes.details?.attributes?.journey_details?.journey_type;
        if (this.bookingDetails.journey_type == 'arrival'){
          this.bookingDetails.airportDetails['terminal_id'] = res.data.attributes.details?.attributes?.journey_details?.arrival_terminal_id
          this.bookingDetails.airportDetails['flight_no'] = res.data.attributes.details?.attributes?.journey_details?.arrival_flight_no
        }
        else if (this.bookingDetails.journey_type == 'departure') {
          this.bookingDetails.airportDetails['terminal_id'] = res.data.attributes?.details?.attributes?.journey_details?.departure_terminal_id
          this.bookingDetails.airportDetails['flight_no'] = res.data.attributes?.details?.attributes?.journey_details?.departure_flight_no
        }
        this.bookingDetails.airportDetails['terminal'] = this.bookingDetails.airportDetails.terminals.find(x=>
          x.terminal_id == this.bookingDetails.airportDetails.terminal_id
        );
        this.bookingDetails.airportDetails['service_date_time'] = res.data.attributes.details.attributes.service_date_time;
        if(res.data.attributes?.details?.attributes?.requested_services?.data && Array.isArray(res.data.attributes?.details?.attributes?.requested_services?.data) && res.data.attributes?.details?.attributes?.requested_services?.data?.length > 0) {
          this.bookingDetails.requestedServices = res.data.attributes.details.attributes.requested_services.data.map(x => {
            x['service_name'] = this.bookingDetails.airportDetails.terminal.services.find(y => y.service_id === x.attributes.gac_service_id).service_name;
            return x;
          });
        }
      }
      setTimeout(() => {
        if(document.getElementById("service-confirmation")) {
          document.getElementById("service-confirmation").scrollIntoView();
        }
      }, 500);
    });
  }

  confirmBooking() {
    this.isConfirming = true;
    this.airportService.confirmBooking(this.bookingId).subscribe((res: any) => {
      if(res.status == 'success') {
        this.toastr.success(res.msg)
        this.nextStep.emit();
      } else {
        this.toastr.error(res.msg)
      }
      this.isConfirming = false;
    }, (err) => {
      this.isConfirming = false;
      this.toastr.error(err?.error?.msg ?? 'Something went wrong! Please try again later');
    });
  }

  ngOnDestroy(): void {
    // sessionStorage.clear()
  }

}
