import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RedemptionService {

  isLoading: EventEmitter<boolean> = new EventEmitter();
  userCards: any;
  memberships: Array<any> = [];
  redemptions: any = [];
  offers = [];

  constructor(private _apiService: ApiService, private authService: AuthenticationService, public globalConstants: GlobalConstants) {
    this.authService.isUserLoggedIn.subscribe(() => {
      this.checkIfUserAuthenticated();
    });
    this.checkIfUserAuthenticated();
  }

  checkIfUserAuthenticated() {
    let currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : null;
    if(currentUser && !currentUser?.isTokenExpired) {
      if(localStorage.getItem("userDetails") && Object.keys(JSON.parse(localStorage.getItem("userDetails"))).length > 0) {
        let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        if(userDetails.mobile && userDetails.status == "activated") {
          this.getPerksData();
        }
      }
    }
  }

  getPerksData() {
    this.isLoading.emit(true);
    forkJoin([this._apiService.getRedemptions(!this.globalConstants?.isFounderCard ? {offer: true}: null), this._apiService.getCards()]).subscribe((response: any) => {
      this.offers = [];
      this.memberships = [];
      this.redemptions = response[0].data;
      this.userCards = response[1].data;
      this.redemptions.forEach(obj => {
        var cardData = this.getCard(obj.attributes.user_card_id);
        if(obj.attributes.membership && Object.keys(obj.attributes.membership)?.length > 0) {
          let dataItem = {
            'id': obj.attributes.membership.id,
            'created_at': obj.attributes.created_at,
            'status': obj.attributes.status,
            'type': 'membership',
            'merchant_name': obj.attributes.merchant.name,
            'membership_name': obj.attributes.offer.title,
            'membership_img': obj.attributes.merchant.logo,
            'voucher': obj.attributes.membership_voucher.code ? obj.attributes.membership_voucher.code : null,
            'website': obj.attributes.membership.redemption_link && obj.attributes.membership.redemption_link != null && obj.attributes.membership.redemption_link != '' ? obj.attributes.membership.redemption_link : obj.attributes.merchant.website,
            'validity': obj.attributes?.membership?.end_date ? moment(obj.attributes.membership.end_date).format("MMM DD YYYY") : 'Unavailable',
            'cardName': cardData ? cardData.attributes.display_name : '',
            'cardNum': cardData ? cardData.attributes.bin : '',
            'cardStatus': cardData ? cardData.attributes.status : '',
            'merchant_logo': obj.attributes.merchant.logo,
            'merchant_id': obj.attributes.merchant.id,
            'is_featured': obj.attributes.bundle.context == 'featured',
            'isExpired': moment(obj.attributes.membership.end_date).isBefore()
          }
          this.memberships.push(dataItem);
        } else {
          let dataItem = {
            'id': obj.attributes.offer.id,
            'created_at': obj.attributes.created_at,
            'status': obj.attributes.status,
            'type': 'offer',
            'merchant_name': obj.attributes.merchant.name,
            'offer_name': obj.attributes.offer.title,
            'offer_img': obj.attributes.merchant.logo,
            'voucher': obj.attributes.voucher.code ? obj.attributes.voucher.code : null,
            'website': obj.attributes.offer.redemption_link && obj.attributes.offer.redemption_link != null && obj.attributes.offer.redemption_link != '' ? obj.attributes.offer.redemption_link : obj.attributes.merchant.website,
            'validity': moment(obj.attributes.offer.end_date).format("MMM DD YYYY"),
            'cardName': cardData ? cardData.attributes.display_name : '',
            'cardNum': cardData ? cardData.attributes.bin : '',
            'cardStatus': cardData ? cardData.attributes.status : '',
            'merchant_logo': obj.attributes.merchant.logo,
            'merchant_id': obj.attributes.merchant.id,
            'is_featured': obj.attributes.bundle.context == 'featured',
            'isExpired': moment(obj.attributes.offer.end_date).isBefore()
          }
          this.offers.push(dataItem);
        }
        
      });
      this.offers.sort(function (a, b) {
        return Date.parse((b.created_at)) - Date.parse((a.created_at));
      });
      this.memberships.sort(function (a, b) {
        return Date.parse((b.created_at)) - Date.parse((a.created_at));
      });
      this.isLoading.emit(false);
    },(err) => { this.isLoading.emit(false); });
  }

  getCard(id) {
    if (this.userCards && this.userCards.length > 0) {
      var filteredCard = this.userCards.filter(x => x.id == id);
      return filteredCard[0];
    }
    else {
      this._apiService.getCards().subscribe(
        (res: any) => {
          this.userCards = res.data;
          var filteredCard = this.userCards.filter(x => x.id == id);
          return filteredCard[0];
        },
        (err) => {
        }
      );
    }
  }
}
