<div
  class="modal-bg"
  [ngStyle]="{ background: GlobalConstants.data.colors.couponModalBgColor }"
>
  <div class="d-flex flex-row pad-top-20 pad-bot-20 pad-left-32 pad-right-32" [ngStyle]="{background: GlobalConstants.data.modalStyling.headerBg, 'box-shadow': (isAirportLoungeFlow || isPriorityCheckIn) ? '0px 4px 22px 2px rgba(236, 155, 38, 0.12)' : ''}">
    <div class="merchant-title cfff fnt-18 f700">
      Your Exclusive Deal!
    </div>
    <img src="../../../../../assets/icons/close.svg" class="close-icon mt-auto mb-auto pointer" (click)="closeModal()" />
  </div>

  <div class="d-flex flex-row pt-4 pad-left-32 pad-right-32 custom-dashed-border top">
    <div *ngIf="isAirportLoungeFlow || isPriorityCheckIn" class="orage-border-container mar-top-7 mb-auto mar-right-10 mar-left-0 rounded-circle">
      <img [src]="couponDetails?.merchantLogoUrl" class="rounded-circle merchant-logo m-0 shadow-none"  />
    </div>
    <img [src]="couponDetails?.merchantLogoUrl" class="rounded-circle merchant-logo" *ngIf="!(isAirportLoungeFlow || isPriorityCheckIn)" />
    <div class="d-flex flex-column">
      <div class="fnt-20 cfff f500 ml-3 align-self-center" [ngStyle]="{'color': (GlobalConstants?.isFounderCard && (isAirportLoungeFlow || isPriorityCheckIn)) ? '#EC9B26' : ''}">
        {{couponDetails?.offerTitle || couponDetails?.title}}
      </div>
      <!-- <div class="d-flex flex-row mar-bot-15 mar-top-15">
        <div class="cfff fnt-18 f600 mar-right-5" *ngIf="couponDetails?.offerPrice">
            <del>
                ₹ {{couponDetails?.offerPrice}}
            </del>
        </div>
        <div class="green-text fnt-18 f600" *ngIf="couponDetails?.original_price">
            {{couponDetails?.offerPrice == couponDetails?.originalPrice ? 'Free' : '₹'+(couponDetails?.originalPrice | indianCurrency)}}
        </div>
      </div> -->
    </div>
  </div>

  <div
    *ngIf="couponLoading"
    class="flex align-center justify-center h100 loader-coupon mar-top-36 mar-bot-36 pad-bot-36"
  >
    <div class="page-loader"></div>
  </div>
  <div *ngIf="!couponLoading" class="mt-4 pad-bot-26">
    <div
      class="modal-body coupon-code-pop p-0 text-center"
      *ngIf="voucherData && (voucherData.success == true)"
    >
      <ng-container>
        <div class="coupon-code-main text-center flex-wrap pad-bot-20 pad-top-0 pad-left-32 pad-right-32">
        
          <div
            class="coupan-code-box m-auto relative whiteBg justify-content-between d-flex flex-row p-2 rounded"
            id="redemption_coupon_code"
            *ngIf="couponDetails?.type != 'no_voucher'"
          >
            <span class="fnt-13 voucher-code f400 m-auto w-75">
                <b>
                    {{voucherData.offer_type && voucherData.offer_type === "link_voucher" ? "No Voucher Required" : voucherData.voucher_code}}
                </b>
            </span>
            <div class="text-capitalize blue-text pointer w-25 text-start" (click)="copy(voucherData.voucher_code)" *ngIf="!showCopiedState">
              copy
            </div>
            <div class="text-capitalize green-text d-flex flex-row" *ngIf="showCopiedState">
              copied <span class="icon-check align-self-center mar-top-2"></span>
            </div>
          </div>
          <div
            class="coupan-code-box m-auto relative whiteBg justify-content-between d-flex flex-row p-2 rounded"
            id="redemption_coupon_code"
            *ngIf="couponDetails?.type == 'no_voucher'"
          >
            <span class="fnt-13 f400 voucher-code m-auto">
              <b>
                {{
                  couponDetails?.id == '88ca346a-6e02-401b-8c58-1465ad994a14' ? 
                  'Successfully Enrolled!' : 
                  ( 
                    (isPriorityCheckIn || isTheKen) ? 
                      'Contact Your EA at 1800 12355 5550 to Redeem' 
                      : 
                      isAirportLoungeFlow ? 
                        'Swipe your RazorpayX Founder Card' 
                        : 
                        'No Voucher Required' 
                  )
                }}
              </b>
            </span>
          </div>
          <div class="validity-box cfff fnt-12 f500 mt-3">
            <small id="validity_coupon_code_return">Valid till:<span class="mar-left-5">{{couponDetails?.endDate | date: "mediumDate"}}</span></small>
          </div>
        </div>

        <div class="coupon-code-footer pad-top-20 custom-dashed-border top pad-left-32 pad-right-32" *ngIf="!(isAirportLoungeFlow || isPriorityCheckIn)">
          <div class="fnt-12 f300 terms-color w-100 m-auto">Please refer to the Offer <div class="text-decoration-underline d-inline pointer" (click)="redirectToTerms()">Terms and Conditions</div> on how to redeem this offer</div>
          <div class="text-center go-to mar-top-20">
            <img
              class="loader"
              width="31px"
              *ngIf="redeeming"
              src="./assets/img/icons/spin.gif"
            />
            <button
              *ngIf="!redeeming"
              class="btn btn-theme shop_now_url fnt-14 cfff custom-button primary w-100 d-flex justify-content-center align-items-center pad-top-10 pad-bot-10"
              (click)="goTo()"
            >
              <div class="f700">
                Redeem Now
              </div> 
              <img src="../../../../../../assets/icons/newTab.svg" class="new-tab-icon mar-left-5" />
            </button>
          </div>
          <div class="mar-top-20 w-100">
            <button class="btn custom-button secondary-founder border-primary outline outline w-100">
              <div class="blue-text f500 fnt-16">
                Back to Card Portal
              </div>
            </button>
          </div>
        </div>
        <div class="d-flex flex-row pad-top-20 pad-left-32 pad-right-32 custom-dashed-border top" *ngIf="isAirportLoungeFlow || isPriorityCheckIn">
          <svg width="20" height="20" class="mar-top-3" viewBox="0 0 23 23" fill="#8F93A4" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3469 16.7165C10.7946 16.7166 10.3468 16.269 10.3467 15.7167L10.3461 11.7167C10.346 11.1644 10.7936 10.7166 11.3459 10.7165C11.8982 10.7165 12.346 11.1641 12.3461 11.7164L12.3467 15.7164C12.3468 16.2687 11.8991 16.7165 11.3469 16.7165Z"/>
            <path d="M11.3453 6.71654C10.793 6.71663 10.3454 7.16441 10.3455 7.7167C10.3455 8.26898 10.7933 8.71663 11.3456 8.71654C11.8979 8.71646 12.3455 8.26867 12.3455 7.71639C12.3454 7.16411 11.8976 6.71646 11.3453 6.71654Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3461 11.7148C22.347 17.79 17.4229 22.7156 11.3478 22.7165C5.27265 22.7175 0.347019 17.7934 0.34608 11.7182C0.345142 5.64311 5.26925 0.717483 11.3444 0.716544C17.4195 0.715606 22.3451 5.63971 22.3461 11.7148ZM11.3475 20.7165C16.318 20.7158 20.3468 16.6857 20.3461 11.7152C20.3453 6.74459 16.3153 2.71578 11.3447 2.71654C6.37413 2.71731 2.34531 6.74737 2.34608 11.7179C2.34685 16.6885 6.37691 20.7173 11.3475 20.7165Z"/>
          </svg>
          <div class="c8F93A4 fnt-12 f400 mar-left-5" style="width: fit-content; text-align: justify;">
            You will recieve the Vocher code through email and SMS. You can use your Unlimited Access to Domestic & International Lounges. Present the Voucher during billing to redeem.
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="modal-body coupon-error text-center pad-top-40 pad-bot-40"
      *ngIf="voucherData && voucherData.success == false"
    >
      <svg
        fill="red"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 512.001 512.001"
        style="enable-background: new 0 0 512.001 512.001"
        xml:space="preserve"
      >
        <g>
          <path
            d="M503.839,395.379l-195.7-338.962C297.257,37.569,277.766,26.315,256,26.315c-21.765,0-41.257,11.254-52.139,30.102    L8.162,395.378c-10.883,18.85-10.883,41.356,0,60.205c10.883,18.849,30.373,30.102,52.139,30.102h391.398    c21.765,0,41.256-11.254,52.14-30.101C514.722,436.734,514.722,414.228,503.839,395.379z M477.861,440.586    c-5.461,9.458-15.241,15.104-26.162,15.104H60.301c-10.922,0-20.702-5.646-26.162-15.104c-5.46-9.458-5.46-20.75,0-30.208    L229.84,71.416c5.46-9.458,15.24-15.104,26.161-15.104c10.92,0,20.701,5.646,26.161,15.104l195.7,338.962    C483.321,419.836,483.321,431.128,477.861,440.586z"
          />
        </g>
        <g>
          <rect x="241.001" y="176.01" width="29.996" height="149.982" />
        </g>
        <g>
          <path
            d="M256,355.99c-11.027,0-19.998,8.971-19.998,19.998s8.971,19.998,19.998,19.998c11.026,0,19.998-8.971,19.998-19.998    S267.027,355.99,256,355.99z"
          />
        </g>
      </svg>
      <div class="fnt-16 ln-28 cfff error-line">
        Something went wrong. Please try again later!
      </div>
    </div>
  </div>
</div>
