<div class="relative d-flex flex-column">
    <img src="../../../../../assets/logos/logo.svg" class="logo" />

    <div class="page-loader" *ngIf="!selectedCountryAvailable"></div>
    <section class="step-one" *ngIf="stepToShow == 'mobile' && selectedCountryAvailable">
        <form [formGroup]="createWithUserForm" (submit)="userSearchByMobile()" autocomplete>
            <div class="row">
                <div class="col-sm-12">
                    <div class="cfff fnt-14 f400 mar-bot-16 mar-top-24">
                        To continue exploring offers curated for you
                    </div>
                    <div class="mar-bot-10 fnt-18 cfff">Authenticate your <div class="d-inline f700">mobile number and email address</div></div>
                    <div class="mobile-block d-flex flex-row mar-top-32 focus-active-border pad-10 custom-border border-rounded-2 relative" [ngClass]="{'warning warning-border': showMobileInvalidMessage }">
                        <div class="country-block d-flex allign-center justify-center searchdrop relative custom-border right" dropdown #dropdown="bs-dropdown" [insideClick]="true">
                            <div class="w100 h100 d-flex align-center pointer relative btn border-0" dropdownToggle>
                                <img [src]="selectedCountry?.attributes?.flag_url" alt="Flag" class="relative country-flag" *ngIf="selectedCountry" />
                                <div class="mar-left-5">
                                    <svg class="left" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 4L0 0L8 6.28116e-07L4 4Z" fill="#98A2B4" />
                                    </svg>
                                </div>
                            </div>
                            <div class="dropdown-menu dropdown-menu-shadow w100 country-list mar-top-6 pad-top-0" *dropdownMenu>
                                <div class="select-input-parent pad-8 w100 z1 pb-0">
                                <input autocomplete="new-password" class="select-input form-control fnt-13 bg-transparent border-0 cfff" [(ngModel)] = "countrySearchTerm" [ngModelOptions]="{standalone: true}" (input) = "filterItem(countrySearchTerm)" placeholder="Search country">
                                </div>
                                <div class="block loc-results">
                                    <div *ngFor="let country of filteredCountries; let i = index" (click)="dropdown.isOpen = false;countrySearchTerm = '';setCountry(country);" [ngClass]="{'selected-country': country.attributes?.iso_code == selectedCountry.iso_code}">
                                    <div class="flex relative country-item pointer pad-10">
                                        <img [src]="country.attributes.flag_url" class="country-flag mt-auto mb-auto">
                                        <div class="item-name flex align-center cfff fnt-14 w100 mar-left-5">
                                            {{country.attributes.name}}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mobile-input flex w100">
                            <input formControlName="mobile" class="form-control h100 w100 border-0 cfff bg-transparent" [(ngModel)]="mobNum" type="text"
                                placeholder="Mobile Number" maxlength="{{selectedCountry?.attributes?.max_mobile_digit}}"
                                minlength="{{selectedCountry?.attributes?.min_mobile_digit}}"
                                (input)="removeLetter($event.target.value);enableContinueButton()" 
                            />
                            <div class="small-loader input-load" *ngIf="searchMobileLoader"></div>
                            <img src="../../../../../assets/icons/warning.svg" class="warning-icon mt-auto mb-auto" *ngIf="showMobileInvalidMessage" />
                        </div>
                        <div class="warning warning-text fnt-12 f600 absolute" *ngIf="showMobileInvalidMessage">
                            Enter Valid Mobile Number
                        </div>
                    </div>

                    <div class="email-block custom-border focus-active-border d-flex flex-row pad-10 mar-top-30 border-rounded-2 relative" [ngClass]="{'warning warning-border': showEmailInvalidMessage }">
                        <div class="mail-icon-container custom-border right d-flex">
                            <span class="icon-mail mar-left-12 mt-auto mb-auto fnt-16 pad-top-4"></span>
                        </div>
                        <input formControlName="email" class="form-control h100 w100 border-0 cfff bg-transparent" type="email" placeholder="E-mail Address" >
                        <img src="../../../../../assets/icons/warning.svg" class="warning-icon mt-auto mb-auto" *ngIf="showEmailInvalidMessage" />
                        <div class="warning warning-text fnt-12 f600 absolute" *ngIf="showEmailInvalidMessage">
                            Enter Valid Email
                        </div>
                    </div>

                    <div class="clearfix relative">
                        <div class="error-txt mar-top-10 cfff" *ngIf="errorRequestingOtp" [innerHtml] = "errorRequestingOtpMsg"></div>
                    </div>

                    <div class="d-flex flex-row mar-top-10">
                        <!-- <img src="../../../../../assets/icons/info.svg" class="info-icon" /> -->
                        <svg width="23" height="23" class="pad-top-3" viewBox="0 0 23 23" [attr.fill]="!GlobalConstants?.isFounderCard ? '#CED4DA' : '#EC9B26'" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3469 16.7165C10.7946 16.7166 10.3468 16.269 10.3467 15.7167L10.3461 11.7167C10.346 11.1644 10.7936 10.7166 11.3459 10.7165C11.8982 10.7165 12.346 11.1641 12.3461 11.7164L12.3467 15.7164C12.3468 16.2687 11.8991 16.7165 11.3469 16.7165Z"/>
                            <path d="M11.3453 6.71654C10.793 6.71663 10.3454 7.16441 10.3455 7.7167C10.3455 8.26898 10.7933 8.71663 11.3456 8.71654C11.8979 8.71646 12.3455 8.26867 12.3455 7.71639C12.3454 7.16411 11.8976 6.71646 11.3453 6.71654Z"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3461 11.7148C22.347 17.79 17.4229 22.7156 11.3478 22.7165C5.27265 22.7175 0.347019 17.7934 0.34608 11.7182C0.345142 5.64311 5.26925 0.717483 11.3444 0.716544C17.4195 0.715606 22.3451 5.63971 22.3461 11.7148ZM11.3475 20.7165C16.318 20.7158 20.3468 16.6857 20.3461 11.7152C20.3453 6.74459 16.3153 2.71578 11.3447 2.71654C6.37413 2.71731 2.34531 6.74737 2.34608 11.7179C2.34685 16.6885 6.37691 20.7173 11.3475 20.7165Z"/>
                        </svg>
                        <div class="fnt-9 f500 mar-left-5" [ngStyle]="{color: !GlobalConstants?.isFounderCard ? '#CED4DA' : '#EC9B26'}">We collect personal information only to communicate the details of your claimed rewards.</div>
                    </div>
                
                    <div class="privacyPolicy_and_terms d-flex flex-row mar-top-30">
                        <input formControlName="privacyPolicy_and_terms" (change)="acceptTermsAndPolicy($event)" type="checkbox"  class="mar-top-5 mb-auto">
                        <div class="CED4DA d-flex mar-left-5" [ngClass]="{'flex-wrap': deviceService.isMobile, 'text-nowrap': !deviceService.isMobile }">I agree to the 
                                <div (click)="openTermsAndConditions()" class="blue-text pointer mar-right-5 mar-left-5"><u>Terms & Conditions</u></div> 
                                and 
                                <div class="blue-text pointer mar-left-5" (click)="openPrivacyPolicy()"><u>Privacy Policy</u></div>
                        </div>
                    </div>

                    <div class="clearfix continue-btn-block mar-top-10">
                        <button class="relative custom-button primary border-rounded-2 w100 btn d-flex justify-content-center pad-10" type="submit"
                            *ngIf="mobileInputBtnActive"
                            [disabled]="isMobileNumberSearching">
                            Continue <div class="small-loader merged-card-loader mar-left-10 mt-auto mb-auto" *ngIf="isMobileNumberSearching"></div></button>
                            <button type="btn" *ngIf="!mobileInputBtnActive" class="custom-button disabled border-rounded-2 w100 btn pad-10" disabled="disabled">Continue</button>
                        </div>

                </div>
            </div>
        </form>
    </section>
    <section class="step-two otp-input" *ngIf="stepToShow == 'otp'">
        <form [formGroup]="validateUserOtpForm">
            <div class="fnt-16 f400 cfff mar-top-48 mar-bot-40">
                <span> OTP has been sent to <span class="pad-right-4" *ngIf = "selectedCountry && selectedCountry.attributes.calling_codes && selectedCountry.attributes.calling_codes[0]">+{{selectedCountry.attributes.calling_codes[0]}}</span>*******{{mobNum | slice:7:10 }} <span *ngIf="createWithUserForm.controls.email.value"> and {{createWithUserForm.controls.email.value}}</span> </span>  
                <span class="back-btn pointer relative fnt-13 pad-left-5" (click)="resetLoginAgain()">(Edit)</span>
            </div>
            <div class="d-flex flex-column mar-bot-60">
                <div class="otp-field warning relative pt-0 mar-bot-20">
                    <div>
                        <input formControlName="otp" id="otp-field" class="form-control bg-transparent focus-active-border custom-border cfff" autocomplete="off" (input)="validateUserOtp(); invalidOtp = false;" max="6" maxlength="6" [(ngModel)]="otpInp" />
                        <div class="input-border flex">
                            <span class="num-1" [ngClass]="{'active' : otpInp?.length == 0 || !otpInp}"></span>
                            <span class="num-2" [ngClass]="{'active' : otpInp?.length == 1}"></span>
                            <span class="num-3" [ngClass]="{'active' : otpInp?.length == 2}"></span>
                            <span class="num-4" [ngClass]="{'active' : otpInp?.length == 3}"></span>
                            <span class="num-5" [ngClass]="{'active' : otpInp?.length == 4}"></span>
                            <span class="num-6" [ngClass]="{'active' : otpInp?.length == 5}"></span>
                        </div>
                        <div class="just"></div>
                        <div class="check" *ngIf="validOtp">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM6 11.25L2.25 7.5L3.3075 6.4425L6 9.1275L11.6925 3.435L12.75 4.5L6 11.25Z"
                                    fill="#15C9B7" 
                                />
                            </svg>
                        </div>
                        <div class="clear pointer" (click)="clearOtp(); invalidOtp = false" *ngIf="!otpVerifying">
                        X
                        </div>
                    </div>
                    <div class="small-loader otp-loader" *ngIf="otpVerifying"></div>
                    <img src="../../../../../assets/icons/warning.svg" class="warning-icon mt-auto mb-auto" *ngIf="errorRequestingOtp && !otpVerifying" />
                    <div class="warning warning-text fnt-12 f600 absolute" *ngIf="errorRequestingOtp">
                        Incorrect OTP! Please try again.
                    </div>
                </div>
                <div class="help-block f500 fnt-13 mar-top-12 cfff" [class.d-none] = "hideResend" *ngIf="viewTimer">Resend Code in {{timerToShow}}</div>

                <div class="check error-txt pad-top-5 cfff" *ngIf="errorRequestingOtp" [innerHtml] = "errorRequestingOtpMsg"></div>
                <button
                    [ngStyle]="{'color': GlobalConstants?.data?.colors?.mainBtn_color, 'border-color': GlobalConstants?.data?.colors?.mainBtn_color}"
                    (click)="resendOtp(); invalidOtp = false; errorRequestingOtp = false;"
                    class="pointer fnt-12 f400 mar-top-4 btn custom-button btn" [ngClass]="{'disabled': hideResend, 'primary': !hideResend}" *ngIf="!viewTimer || viewTimer.length == 0">Resend
                    Code
                </button>
            </div>
        </form>
    </section>
    <div class="footer-info d-flex flex-row mar-top-39 pad-top-10 pad-bot-10 pad-left-30 pad-right-30" [ngStyle]="{background: GlobalConstants?.data?.modalStyling?.footerBg}">
        <div class="fnt-12 cfff f400">
            Your Card/Account parameters are used only to check eligibility and are encrypted
        </div>
        <img src="../../../../../assets/icons/pci.svg" class="pci-complaint-icon mt-auto mb-auto" />
    </div>
</div>
