import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { airport, AirportService } from 'src/app/common/services/airport.service';
import { DeviceService } from 'src/app/common/services/device.service';

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss']
})
export class BookingSummaryComponent implements OnInit {

  pickUpDate: string = "";
  pickUpTime: string = "";
  searchAvailability: any = null;
  tripType: string = "";
  selectedAirport: airport;
  selectedTerminal: any;

  constructor(
    public deviceService: DeviceService,
    private airportService: AirportService,
  ) {
    this.initializeBookingSummary();
  }

  ngOnInit(): void {
  }

  initializeBookingSummary() {
    this.searchAvailability = sessionStorage.getItem("searchAvailabilityForm") ? JSON.parse(sessionStorage.getItem("searchAvailabilityForm")) : {};
    let service_objective = sessionStorage.getItem("service_objective");
    this.selectedAirport = sessionStorage.getItem("selectedAirport") ? JSON.parse(sessionStorage.getItem("selectedAirport")): {};
    this.selectedTerminal = sessionStorage.getItem("selectedTerminal");
    if(this.searchAvailability && Object.keys(this.searchAvailability).length > 0 && service_objective) {
      this.pickUpDate = moment(this.searchAvailability.date).format("ll");
      this.pickUpTime = moment(`${this.searchAvailability.hour}:${this.searchAvailability.mins} ${this.searchAvailability.time_type}`, "hh:mm a").format("hh:mm a");
      this.tripType = service_objective == this.airportService.objectives.DEPARTURE ? "Drop to Airport" : "Pick-up from Airport";

    }
  }

}
