import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationComponent } from 'src/app/shared/components/shared/authentication/authentication.component';
import { ConciergeLoginComponent } from 'src/app/shared/components/shared/concierge-login/concierge-login.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { GlobalConstants } from '../constants/global-variables';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public isUserLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();
  public tokenExpired: EventEmitter<boolean> = new EventEmitter<boolean>();
  dialogRef: any;
  conciergeLoginModalRef: any;

  toBeRemoved = [
    'https://sandbox-razorpay-founders.poshvine.com',
    'https://sandbox-razorpay-founders.poshvine.com',
    'https://razorpayxfounders.poshvine.com',
    'http://sandbox-razorpay-founder.poshvine.in:4200'
  ]

  constructor(
    private _apiService: ApiService,
    private router: Router,
    private GlobalConstants: GlobalConstants,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private globalConstants: GlobalConstants
  ) {
    if(localStorage.getItem("currentUser") && !window.location.pathname.includes("sso_login")) {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      if(currentUser && currentUser.token) {
        const token = JSON.parse(atob(currentUser.token.split('.')[1]));
        const expiresOn = new Date(token.exp * 1000);
        if(expiresOn < new Date(Date.now())) {
          this.setTokenExpiredState();
        } else {
          setTimeout(() => {
            this.setTokenExpiredState();
          }, Math.abs(Math.floor(new Date().getTime() - (token.exp * 1000))));
        }
      }
    }
  }

  isTokenExpired() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if(currentUser && currentUser.token) {
      const token = JSON.parse(atob(currentUser.token.split('.')[1]));
      const expiresOn = new Date(token.exp * 1000);
      if(expiresOn < new Date(Date.now())) {
        return true;
      }
    }
  }

  setTokenExpiredState() {
    localStorage.removeItem("userDetails");
    if(localStorage.getItem("currentUser")) {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      currentUser.token = '' ;
      currentUser.isTokenExpired = true;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }
    this.router.navigate(['/']);
    this.tokenExpired.emit(true);
  }

  processDataQuery(params, targetUrl){
    localStorage.removeItem('currentUser');
    localStorage.removeItem('expire_at');
    localStorage.removeItem('userDetails');
    if (params.cf) {
      let channel_ref = params.cf.toLowerCase();
      localStorage.setItem('channel_ref', channel_ref);
      if (channel_ref == 'mb') {
        localStorage.setItem('appUser', 'true');
      }
    }
    if (params.tk && params.tk != 'no-token') {
      let urlcode = params.tk;
      localStorage.setItem('urlcode', urlcode);
      var user = {
        token: urlcode,
        skipExceptCard: true
      }
      localStorage.setItem('currentUser', JSON.stringify(user));
      this._apiService.getUserDetails().subscribe(
        (res: any) => {
          user['id'] = res.id;
          localStorage.setItem("userDetails", JSON.stringify(res));
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.isUserLoggedIn.emit(true);
          if(this.GlobalConstants.client != "Founder") {
            this.router.navigate(["/"]);
          } else if(this.GlobalConstants.client == "Founder" && targetUrl) {
            let targeturl = decodeURIComponent(targetUrl);
            this.toBeRemoved.forEach(obj => {
              targeturl = targeturl.replace(obj,'');
            });
            localStorage.setItem("targetUrl", targeturl);
            this.router.navigate([targeturl]);
          }
        },
        (err) => {
          //toastr message may be or need to ask user to login
        }
      );
    }
  }

  checkIfUserAuthenticated() {
    if(this.globalConstants.isConcierge) {
      let currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : null;
      if(!currentUser?.token) {
        this.showConciergeLoginModal();
      } else {
        document.body.className.replace("overflow-hidden", "").trim();
      }
    } else {
      let currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : null;
      if(currentUser && !currentUser?.isTokenExpired) {
        if(localStorage.getItem("userDetails") && Object.keys(JSON.parse(localStorage.getItem("userDetails"))).length > 0) {
          let userDetails = JSON.parse(localStorage.getItem("userDetails"));
          if(userDetails.mobile && userDetails.status == "activated") {
            document.body.className.replace("overflow-hidden", "").trim();
          } else {
            this.showAuthenticationModal();
          }
        } else {
          this.showAuthenticationModal();    
        }
      } else {
        this.authService.tokenExpired.emit(true);
      }
    }
  }

  showConciergeLoginModal() {
    if(!this.conciergeLoginModalRef) {
      document.body.classList.add("overflow-hidden")
      this.conciergeLoginModalRef = this.dialog.open(ConciergeLoginComponent, {disableClose: true, panelClass: this.globalConstants.isFounderCard ? ["authenticationModal","founders-modal-bg"] : ["authenticationModal"]}).afterClosed().subscribe((response) => {
        if(response) {
          this.conciergeLoginModalRef = null;
          location.reload(); 
        }
      })
    }
  }

  showAuthenticationModal() {
    if(!this.dialogRef) {
      document.body.classList.add("overflow-hidden")
      this.dialogRef = this.dialog.open(AuthenticationComponent, { disableClose: true, panelClass: this.GlobalConstants.isFounderCard ? ["authenticationModal", "founders-modal-bg"] : ["authenticationModal"] }).afterClosed().subscribe((response) => {
        if(response) {
          this.dialogRef = null;
          if(this.globalConstants.client == "Business") {
            location.reload();
          }
        }
      });
    }
  }

}
