import { Component, Input, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/constants/global-variables';

interface airportBooking {
    'id': string;
    'created_at': string;
    'status': string;
    'type': string;
    'pick_up_address': string;
    'destination_address': string;
    'transport_distance': string;
    'vehicle_type': string;
    'traveller_name': string;
    'traveller_type': string;
    'traveller_count': string;
    'validity': string,
    'flight_no': string,
    'isExpired': string,
    'date_time': string,
    'journey_type': string
}

@Component({
  selector: 'app-airport-transfer-booking',
  templateUrl: './airport-transfer-booking.component.html',
  styleUrls: ['./airport-transfer-booking.component.scss']
})
export class AirportTransferBookingComponent implements OnInit {

  @Input() booking: airportBooking;

  constructor(
    public globalConstants: GlobalConstants
  ) {
  }

  ngOnInit(): void {
  }

}
