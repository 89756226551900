<div class="h-100 min-230 position-relative">
    <div class="custom-padding d-flex flex-row hide-scrollbar justify-content-between mt-2 overflow-auto pb-0 tab-styling position-sticky top-0 secondary-dark" (scroll)="onScroll($event)" [ngClass]="{'concierge-greyish-bg': globalConstants.isConcierge && globalConstants.isFounderCard}" *ngIf="!categoriesLoading">
        <div class="tab-name pointer text-nowrap fnt-16" (click)="showAll()" [ngClass]="{'active': !this.selectedCategory }">
            All Categories
        </div>
        <ng-container *ngIf="!categoriesLoading && inViewCategories.length > 0">
            <div 
                *ngFor="let category of inViewCategories" 
                class="tab-name pointer f700 text-nowrap fnt-16"
                (click)="handleCategorySelect(category)"
                [ngClass]="{'active': this.selectedCategory?.attributes?.name == category?.attributes?.name}"
            >
                <img [src]="categoryIcons[category?.attributes?.code]" class="category-icon mar-right-5" />
                {{category?.attributes?.name}}
            </div>
            <div class="btn-group tab-name position-static fnt-16" *ngIf="inDropdownCategories.length > 0" dropdown>
                <div dropdownToggle type="button" class="d-flex text-nowrap fnt-16 flex-row m-auto relative h100" #dropdownRef (click)="handleDropdownToggle()">
                    <div class="fnt-16 text-center mar-bot-5 align-self-center">More Categories</div>
                    <span class="icon-angle-down fnt-16"></span>
                </div>
            </div>
        </ng-container>
    </div>
    <ul class="dropdown-menu align-self-center" [ngClass]="{'show':showDropdown}" role="menu" #dropdownMenu>
        <li role="menuitem" *ngFor="let category of inDropdownCategories; let i = index" [ngClass]="{'concierge-greyish-bg founders': globalConstants.isConcierge && globalConstants.isFounderCard}">
            <a class="dropdown-item" (click)="handleCategoryFromDropdown(category, i)">
                {{category?.attributes?.name}}
            </a>
        </li>
    </ul>
<div class="darkBg merchants-list flex-wrap d-flex justify-content-start custom-padding pb-5 align-content-start pad-top-56 pad-bot-xs-20">
        <ng-container *ngIf="merchantsLoading">
            <div class="offer-card-border pointer mar-bot-10 mar-left-xs-auto mar-right-xs-auto mar-right-10" *ngFor="let i of [0,1,2,3]">
                <ngx-skeleton-loader 
                    *ngIf="!globalConstants.isFounderCard"
                    class="pad-0 d-flex justify-content-between" 
                    count="1" 
                    appearance="line"
                    [theme]="{
                        'height': '150px',
                        'width': '300px',
                        'border-radius': '8px',
                        'background': '#242A4A',
                        'margin-bottom': '0px'
                    }"  
                >
                </ngx-skeleton-loader>
                <ngx-skeleton-loader 
                    *ngIf="globalConstants.isFounderCard"
                    class="pad-0 d-flex justify-content-between" 
                    count="1" 
                    appearance="line"
                    [theme]="{
                        'height': '150px',
                        'width': '300px',
                        'border-radius': '8px',
                        'background': '#141414',
                        'margin-bottom': '0px'
                    }"  
                >
                </ngx-skeleton-loader>
            </div>
        </ng-container>
        <ng-container *ngIf="!merchantsLoading && categoryBasedMerchants.length > 0">
            <div *ngFor="let merchant of (showMore ? categoryBasedMerchants : (categoryBasedMerchants|slice:0:12))" class="offer-card-container offer-card-border pointer mar-bot-10 mar-left-xs-auto mar-right-xs-auto" [ngClass]="{'concierge-founders': globalConstants.isConcierge && globalConstants.isFounderCard}" [routerLink]="'/rewards/offer-details/'+merchant.id" [queryParams]="{categoryId: this.selectedCategory?.id}">
                <offer-card  [cardStyling]="this.cardStyle" [merchant]="merchant" [callBack]="claimOffer"></offer-card>
            </div>
        </ng-container>
    </div>
    <div class="darkBg pb-5" *ngIf="!showMore && categoryBasedMerchants && (categoryBasedMerchants.length > 12)">
        <button
          class="custom-button primary btn d-flex m-auto mt-0 pad-left-50 pad-right-50 rounded-0"
          (click)="showMore = !showMore"
        >
          <div>See more Offers</div>
          <span class="icon-angle-down position-relative right-0 mar-left-5 pad-top-3"></span>
        </button>
    </div>
</div>