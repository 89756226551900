<div class="d-flex flex-row pad-16" style="background-color: var(--buttonSecondaryBgActive);" [ngStyle]="{'background': globalConstants.data.colors.subHeaderMobile  }" *ngIf="isMobile">
    <span class="icon-historyicon fnt-20 cfff pad-top-5"></span>
    <div class="fnt-18 cfff f700 mar-left-10 align-self-center">Claimed Rewards</div>
</div>
<div class="custom-padding" *ngIf="globalConstants.isFounderCard" [ngClass]="{'mt-3 mb-3': !isMobile, 'pt-0': isMobile}">
    <div class="d-flex flex-row header pad-top-10 pad-bot-10" [ngClass]="{'pad-left-15 pad-right-15': isMobile, 'pad-left-43 pad-right-43': !isMobile}">
        <div class="d-flex flex-row" (click)="goToTargetUrl()">
            <span class="icon-left-arrow mt-auto mb-auto pad-top-6 blue-text fnt-14 pointer"></span>
            <div class="fnt-14 text-capitalize mt-auto mb-auto mar-left-10 text-truncate blue-text pointer">
                Back
            </div>
        </div>
        <div class="d-flex flex-column" [ngStyle]="{'margin-left': !isMobile ? '8.5%' :'' }" [ngClass]="{'mar-left-25': isMobile}">
            <div class="cfff f700" [ngClass]="{'fnt-12': isMobile, 'fnt-20': !isMobile}">
                Claimed Rewards
            </div>
            <div class="description fnt-14 f500">
                Check your claimed rewards here
            </div>
        </div>
    </div>
</div>
<div class="d-flex flex-column custom-padding pad-xs-0 min-height-100 align-items-center pt-0" *ngIf="globalConstants.isFounderCard">
    <tabset type="pills" [vertical]="false" class="redemption">
        <tab heading="Offers">
            <ng-container *ngFor="let redemption of offers">
                <app-offer-claimed-card class="w-100 d-flex justify-content-center" [redemption]="redemption" (copy)="copy($event)" (goTo)="goTo($event)" (redirectToMerchantTerms)="redirectToMerchantTerms($event)"></app-offer-claimed-card>
            </ng-container>
        </tab>
        <tab heading="Memberships">
            <ng-container *ngFor="let redemption of memberships">
                <app-membership-claimed-card class="w-100 d-flex justify-content-center" [redemption]="redemption" (copy)="copy($event)" (goTo)="goTo($event)" (redirectToMerchantTerms)="redirectToMerchantTerms($event)"></app-membership-claimed-card>
            </ng-container>
        </tab>
        <tab heading="Airport Benefits" *ngIf="!fetchingAirportsBookings">
            <ng-container *ngFor="let airportBooking of airportBookings">
                <app-airport-transfer-booking class="w-100 d-flex justify-content-center" [booking]="airportBooking" ></app-airport-transfer-booking>
            </ng-container>
        </tab>
      </tabset>
</div>
<div *ngIf="!globalConstants.isFounderCard" class="d-flex flex-column custom-padding pad-xs-0 min-height-100 align-items-center pt-4">
    <ng-container *ngFor="let redemption of offers">
        <app-offer-claimed-card class="w-100 d-flex justify-content-center" [redemption]="redemption" (copy)="copy($event)" (goTo)="goTo($event)" (redirectToMerchantTerms)="redirectToMerchantTerms($event)"></app-offer-claimed-card>
    </ng-container>
</div>