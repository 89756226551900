import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent implements OnInit {

  contactForm: any = this.formBuilder.group({
    first_name: ["", Validators.required],
    last_name: ["", Validators.required],
    email: ["", [Validators.email, Validators.required]],
    mobile: ["", Validators.required]
  });
  updatingContactDetails: boolean = false;
  userDetails: any;
  failedToUpdate: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ContactModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {
    this.userDetails = this.data.userDetails;
    if(this.userDetails) {
      if(this.userDetails.first_name && this.userDetails.last_name) {
        this.contactForm.controls.first_name.setValue(this.userDetails.first_name);
        if(this.userDetails.first_name) this.contactForm.controls.first_name.disable();
        this.contactForm.controls.last_name.setValue(this.userDetails.last_name);
        if(this.userDetails.last_name) this.contactForm.controls.last_name.disable();
      } else if(this.userDetails.full_name) {
        let firstName = this.userDetails.full_name.split(" ").shift();
        let lastName = this.userDetails.full_name.split(" ").slice(1).join(" ").trim();
        this.contactForm.controls.first_name.setValue(firstName);
        if(firstName) this.contactForm.controls.first_name.disable();
        this.contactForm.controls.last_name.setValue(lastName);
        if(lastName) this.contactForm.controls.last_name.disable();
      }
      if(this.userDetails.email) {
        this.contactForm.controls.email.setValue(this.userDetails.email || "");
        this.contactForm.controls.email.disable();
      }
      if(this.userDetails.mobile) {
        this.contactForm.controls.mobile.setValue(this.userDetails.mobile);
        this.contactForm.controls.mobile.disable();
      }
    }
  }

  ngOnInit() {
  }

  handleSubmit() {
    if(this.contactForm.status == "DISABLED" || (this.contactForm.valid && this.contactForm.status == "VALID")) {
      let params = {
        first_name: this.contactForm.controls.first_name.value,
        last_name: this.contactForm.controls.last_name.value,
        email: this.contactForm.controls.email.value,
        mobile: this.contactForm.controls.mobile.value,
      }
      this.updatingContactDetails = true;
      this.apiService.saveProfile(params).subscribe((response: any) => {
        if(response.success) {
          this.dialogRef.close({
            membership: this.data.membership,
            merchant: this.data.merchant
          })
        } else {
          this.failedToUpdate = true;
          this.toastr.warning("Something went wrong! Please try again later");
        }
        this.updatingContactDetails = false;
      }, error => {
        this.updatingContactDetails = false;
        this.failedToUpdate = true;
        this.toastr.warning("Something went wrong! Please try again later");
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

}
