import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { ApiService } from 'src/app/shared/services/api.service';
import * as moment from "moment";
import { DeviceService } from 'src/app/common/services/device.service';
import { RedemptionService } from 'src/app/shared/services/redemption.service';
import { AirportService } from 'src/app/common/services/airport.service';

@Component({
  selector: 'app-claimed-rewards',
  templateUrl: './claimed-rewards.component.html',
  styleUrls: ['./claimed-rewards.component.scss']
})
export class ClaimedRewardsComponent implements OnInit {

  reloadSubscription: Subscription;
  modalRef: BsModalRef;
  @ViewChild('redeemModal') redeemModal: TemplateRef<any>;
  openedItem: number = -1;
  historyItems = [];
  copiedCode: string;
  //device detector below
  isLoading: boolean = true;
  selectedBookingId;
  reason;
  isMobile: boolean = false;
  offers: Array<any> = [];
  memberships: Array<any> = []
  targetUrl: string = null;
  airportBookings: Array<any> = [];
  fetchingAirportsBookings: boolean = false;


  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private _apiService: ApiService,
    public globalConstants: GlobalConstants,
    private router: Router,
    public meta: Meta,
    public pageTitle: Title,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private deviceService: DeviceService,
    private redemptionService: RedemptionService,
    private airportService: AirportService,
  ) {
    this.deviceService.deviceObserver.subscribe((isMobile) => {
      this.isMobile = isMobile;
    })
    this.redemptionService.isLoading.subscribe((loading) => {
      if(loading) {
        this.isLoading = loading;
      } else {
        this.isLoading = false;
        this.offers = this.redemptionService.offers;
        this.memberships = this.redemptionService.memberships;
      }
    });
    this.airportService.isAirportBookingsLoading.subscribe((loading: boolean) => {
      if(loading) {
        this.fetchingAirportsBookings = true;
      } else {
        this.airportBookings = this.airportService.airportBookings;
        this.fetchingAirportsBookings = false;
      }
    })
    if(localStorage.getItem("targetUrl")) {
      this.targetUrl = localStorage.getItem("targetUrl");
    }
  }
  ngOnDestroy() {
    this.removeSubscriptions();
    this.elementRef.nativeElement.remove();
  }
  removeSubscriptions(){
    if(this.reloadSubscription){
      this.reloadSubscription.unsubscribe()
    }
  }
  ngOnInit() {
    this.offers = this.redemptionService.offers;
    this.memberships = this.redemptionService.memberships;
    this.isMobile = this.deviceService.isMobile;
    this.airportBookings = this.airportService.airportBookings;
  }

  goToTargetUrl() {
    if(this.targetUrl) {
      this.router.navigate([this.targetUrl]);
    }    
  }

  toLocal(num) {
    return Number(num).toLocaleString('en-IN')
  }

  backClicked() {
    this.location.back();
  }
  copy(code) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Copied','', {
      timeOut: 4000,
    });
  }



  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }


  selectedItem: any;
  termsLoading = true;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'showTermsModal' });
  }
  redeemOffer(item) {
    this.termsLoading = true;
    this.selectedItem = item;
    this._apiService.getTerms(item.id).subscribe(
      (res: any) => {
        this.selectedItem.terms = res.terms;
        this.termsLoading = false;
      },
      (err) => {
        this.termsLoading = false;
      }
    );
  }
  goTo(selectedOfr) {
    let gotourl = selectedOfr.website;

    setTimeout(() => {
      window.open(gotourl, '_blank');
    }, 0)

  }

  checkCardDisplayName(name) {
    const strArr = name.split(' ');
    const lastStr = strArr[strArr.length - 1];
    return (/^xx(\d+)$/.test(lastStr));
  }

  redirectToMerchantTerms(item) {
    this.router.navigate(['/'+(item.type == 'offer' ? 'rewards/offer-details' : 'memberships/membership-details')+'/'+item.merchant_id+(item.is_featured ? '/featured' : '') , {showTerms: true}]);
  }

}
