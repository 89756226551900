<div 
    class="d-flex flex-column claimed-reward-card pad-24 mar-bot-20" 
    [ngClass]="{'expired': booking?.isExpired}" 
    [ngStyle]="{background: !booking?.isExpired ? globalConstants.data?.redemptionHistoryStyle?.success?.cardBackgroundColor : globalConstants.data?.redemptionHistoryStyle?.not_success?.cardBackgroundColor}"
>
    <div 
        class="d-flex flex-row merchant-and-status w100 pad-16" 
        [ngStyle]="{background: !booking?.isExpired ? globalConstants.data?.redemptionHistoryStyle?.success?.statusBackgroundColor : globalConstants.data?.redemptionHistoryStyle?.not_success?.statusBackgroundColor}"
    >
        <img src="../../../../../../assets/icons/airport-transfer.svg" class="merchant-logo" />
        <div class="d-flex flex-row justify-content-between w100">
            <div class="d-flex flex-column mar-left-10">
                <div class="fnt-16 f700 cfff">Airport Pickup & Drop</div>
                <div class="fnt-12 f500 offer-title">Travel in comfort by booking a personal vehicle</div>
            </div>
            <div 
                class="status pad-left-10 pad-right-10 mt-auto mb-auto text-center text-capitalize" 
                [ngClass]="{'status-completed': (booking?.status == 'booking confirmed' && !booking?.isExpired), 'status-expired': (booking?.isExpired || (booking?.status != 'booking confirmed')) }"
            >
                {{booking?.isExpired ? 'expired' : booking?.status}}
            </div>
        </div>
    </div>
    <div class="custom-border top d-flex justify-content-between flex-row fnt-14 f400 white-variant-light mar-top-10 pad-10 pad-xs-10 pb-0 pad-xs-left-0 pad-xs-right-0 row">
        <div class="fnt-14 f400 col-12 col-md-4">Booking ID :</div>
        <div class="cfff fnt-16 f500 col-12 col-md-8">{{booking.id}}</div>
    </div>
    <div class="custom-border top d-flex justify-content-between flex-row fnt-14 f400 white-variant-light mar-top-10 pad-10 pad-xs-10 pb-0 pad-xs-left-0 pad-xs-right-0 row">
        <div class="fnt-14 f400 d-flex col-12 col-md-4">Flight Number :</div>
        <div class="cfff fnt-16 f500 col-12 col-md-8">{{booking.flight_no}}</div>
    </div>
    <div class="custom-border top d-flex justify-content-between flex-row fnt-14 f400 white-variant-light mar-top-10 pad-10 pad-xs-10 pb-0 pad-xs-left-0 pad-xs-right-0 row">
        <div class="fnt-14 f400 d-flex col-12 col-md-4 text-capitalize">{{booking?.journey_type}} Date & Time :</div>
        <div class="cfff fnt-16 f500 col-12 col-md-8">{{booking.date_time}}</div>
    </div>
    <div class="custom-border top d-flex justify-content-between flex-row fnt-14 f400 white-variant-light mar-top-10 pad-10 pad-xs-10 pb-0 pad-xs-left-0 pad-xs-right-0 row">
        <div class="d-flex flex-column col-6 col-md-4">
            <div class="fnt-14 f400 d-flex text-capitalize">Traveller :</div>
            <div class="c8F93A4 fnt-14 f500 mar-top-5">{{booking?.traveller_name}}</div>
            <div class="c8F93A4 fnt-14 f500">{{booking?.traveller_type}}</div>
        </div>
        <div class="cfff fnt-16 f500 col-6 col-md-8">{{booking.traveller_count}}</div>
    </div>
    <div class="custom-border top d-flex justify-content-between flex-row fnt-14 f400 white-variant-light mar-top-10 pad-10 pad-xs-10 pb-0 pad-xs-left-0 pad-xs-right-0 row">
        <div class="fnt-14 f400 d-flex col-12 col-md-4 text-capitalize">Pick-up Address :</div>
        <div class="cfff fnt-16 f500 col-12 col-md-8">{{booking.pick_up_address}}</div>
    </div>
    <div class="custom-border top d-flex justify-content-between flex-row fnt-14 f400 white-variant-light mar-top-10 pad-10 pad-xs-10 pb-0 pad-xs-left-0 pad-xs-right-0 row">
        <div class="fnt-14 f400 d-flex col-12 col-md-4 text-capitalize">Drop Address :</div>
        <div class="cfff fnt-16 f500 col-12 col-md-8">{{booking.destination_address}}</div>
    </div>
    <div class="custom-border top d-flex justify-content-between flex-row fnt-14 f400 white-variant-light mar-top-10 pad-10 pad-xs-10 pb-0 pad-xs-left-0 pad-xs-right-0 row">
        <div class="fnt-14 f400 d-flex col-12 col-md-4 text-capitalize">Vehicle Type and Distance :</div>
        <div class="cfff fnt-16 f500 col-12 col-md-8">{{booking.vehicle_type}} | {{booking.transport_distance}}</div>
    </div>
    <div class="mar-top-10 pad-16 custom-border top d-flex flex-row pb-0 pad-xs-left-0 pad-xs-right-0">
        <img src="../../../assets/icons/warning.svg"/>
        <div class="warning warning-text fnt-12 f400 mar-left-10">
            You will recieve the Vocher code through email and SMS. You can use your Unlimited Access to Domestic & International Lounges. In case of in-store usage, present the Voucher during billing to redeem.
        </div>
    </div>
</div>