import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { DeviceService } from 'src/app/common/services/device.service';

@Component({
  selector: 'app-membership-claimed-card',
  templateUrl: './membership-claimed-card.component.html',
  styleUrls: ['./membership-claimed-card.component.scss']
})
export class MembershipClaimedCardComponent implements OnInit {

  @Input() redemption: any;
  @Output() copy = new EventEmitter<any>();
  @Output() goTo = new EventEmitter<any>();
  @Output() redirectToMerchantTerms = new EventEmitter<any>();


  constructor(
    public globalConstants: GlobalConstants,
    public deviceService:DeviceService
  ) {}

  ngOnInit(): void {
  }

}
