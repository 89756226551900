import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MerchantsService {

  private merchantsStore: any = {};
  categories: Array<any> = [];

  selectedCategory: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  updateMerchantStore(key: string, merchants: Array<any>, isFeatured: boolean = false) {
    this.merchantsStore[key] = {
      isFeatured: isFeatured,
      merchants: merchants
    };
  }

  getMerchantsBy(key: string = "all", isFeatured: boolean = false) {
    if(this.merchantsStore[key] && this.merchantsStore[key].merchants && this.merchantsStore[key].isFeatured == isFeatured) {
      return JSON.parse(JSON.stringify(this.merchantsStore[key].merchants));
    } else {
      return [];
    }
  }

}
