import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AirportService, airport } from 'src/app/common/services/airport.service';
import { DeviceService } from 'src/app/common/services/device.service';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss']
})
export class ServiceFormComponent implements OnInit {

  @Output() showTransportServiceForm: EventEmitter<any> = new EventEmitter<any>();

  timeType = ['am', 'pm'];
  emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  serviceForm: FormGroup = this.formBuilder.group({
    flight_origin: ['', Validators.required],
    flight_destination: ['', Validators.required],
    
    flight_number: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(8), Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d*)[A-Za-z\d]{2}[\s]?(?=.*?[0-9])[0-9]{1,4}$/)]],
    journey_type: ['', Validators.required],
    adults: [1, [Validators.required, Validators.minLength(1), Validators.maxLength(9), Validators.pattern(/^[1-9]\d*$/)]],
    children: [0, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    infants: [0, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    traveller_title: ["", Validators.required],
    traveller_first_name: ["", Validators.required],
    traveller_last_name: ['', Validators.required],
    pnr_number: ["", Validators.required],
    travel_class: ['', [Validators.required, Validators.minLength(2)]],
    traveller_date_of_birth: ['', Validators.required],
    traveller_mobile: ["", [Validators.required]],
    traveller_email: ['', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
    journey_date: [''],
    journey_hour: [1],
    journey_mins: [0],
    journey_time_type: [this.timeType[0]],
    small: [0, [Validators.required]],
    medium: [0, [Validators.required]],
    large: [0, [Validators.required]]
  });
  flightClasses: Array<string> = ["Economy", "Premium Economy", "Business", "First"];
  dateOfBirth: Date;
  formSubmited: boolean = false;
  maxTravellers = 9;
  selectedJourney: string = "";
  mobNum: string = "";
  isValid: boolean = false;
  journeyDate: Date;
  selectedOriginAirport: airport;
  selectedDestinationAirport: airport;
  flightOriginSearchQuery: string = "";
  flightDestinationSearchQuery: string = "";
  personType = ['Mr.', 'Mrs.', 'Miss.'];
  minJourneyDate: Date;
  maxJourneyDate: Date;
  isSavingServiceForm: boolean = false;
  failedToSave: boolean = false;
  failureMsg: string = "";
  selectedCountry: any;
  countrySearchTerm: string = "";
  filteredCountries: Array<{}> = [];
  searchMobileLoader: boolean = false;
  mobileInputBtnActive: boolean = false;
  isMobileNumberSearching: boolean = false;
  errorRequestingOtpMsg: string = "";
  errorRequestingOtp: boolean = false;
  selectedCountryAvailable: boolean =  false;
  countries: Array<any> = [];
  searchedAirports: Array<any> = [];

  constructor(
    public deviceService: DeviceService,
    private formBuilder: FormBuilder,
    public airportService: AirportService,
    private toastr: ToastrService,
    private apiService: ApiService
  ) {
    if(sessionStorage.getItem("service_objective")) {
      this.serviceForm.controls.journey_type.setValue(sessionStorage.getItem("service_objective"));
      this.selectedJourney = sessionStorage.getItem("service_objective");
      let searchAvailabilityForm = sessionStorage.getItem("searchAvailabilityForm") ? JSON.parse(sessionStorage.getItem("searchAvailabilityForm")) : {};
      if(this.selectedJourney == this.airportService.objectives.DEPARTURE) {
        this.selectedOriginAirport = JSON.parse(sessionStorage.getItem("selectedAirport"));
        this.flightOriginSearchQuery = this.selectedOriginAirport.airport_name;
        this.serviceForm.controls.flight_origin.setValue(this.selectedOriginAirport?.airport_name);
        this.serviceForm.controls.flight_origin.disable();
        this.serviceForm.controls.journey_hour.addValidators(Validators.min(searchAvailabilityForm.hour));
        this.serviceForm.controls.journey_mins.addValidators(Validators.min(searchAvailabilityForm.mins));
        this.serviceForm.controls.journey_date.addValidators([Validators.required, Validators.minLength(2)]);
        this.serviceForm.controls.journey_hour.addValidators([Validators.required, Validators.minLength(1)]);
        this.serviceForm.controls.journey_mins.addValidators([Validators.required, Validators.minLength(1)]);
        this.serviceForm.controls.journey_time_type.addValidators([Validators.required, Validators.minLength(2)]);
        this.minJourneyDate = new Date(searchAvailabilityForm.date);
      } else if(this.selectedJourney == this.airportService.objectives.ARRIVAL) {
        this.selectedDestinationAirport = JSON.parse(sessionStorage.getItem("selectedAirport"));
        this.flightDestinationSearchQuery = this.selectedDestinationAirport.airport_name;
        this.serviceForm.controls.flight_destination.setValue(this.selectedDestinationAirport?.airport_name);
        this.serviceForm.controls.flight_destination.disable();
        this.serviceForm.controls.journey_hour.addValidators(Validators.max(searchAvailabilityForm.hour));
        this.serviceForm.controls.journey_mins.addValidators(Validators.max(searchAvailabilityForm.mins));
        this.maxJourneyDate = new Date(new Date(searchAvailabilityForm.date).getTime() + 24 * 60 * 60 * 1000);
      }
    }
    if(sessionStorage.getItem("airports")) {
      let airports = JSON.parse(sessionStorage.getItem("airports"));
      let selectedAirport = JSON.parse(sessionStorage.getItem("selectedAirport"));
      this.searchedAirports = airports.filter((airport) => airport.id != selectedAirport.id);
    }
    this.serviceForm.valueChanges.subscribe(() => {
      if(this.serviceForm.valid) {
        this.isValid = true;
        this.failureMsg = "";
      } else {
        this.isValid = false;
      }
    })
  }

  ngOnInit(): void {
    if (localStorage.getItem("countries")) {
      this.countries = JSON.parse(localStorage.getItem("countries"));
      this.filteredCountries = this.countries;
      let india;
      this.filteredCountries = this.filteredCountries.filter((country: any) => {
        if(country.attributes.name.toLowerCase() != "india") {
          return true;
        } else {
          india = country;
          return false;
        }
      });
      this.filteredCountries.sort((a: any,b: any) => {
        if(a.attributes.name.charAt(0).toLowerCase() < b.attributes.name.charAt(0).toLowerCase()) { return -1; }
        if(a.attributes.name.charAt(0).toLowerCase() > b.attributes.name.charAt(0).toLowerCase()) { return 1; }
        return 0;
      });
      this.filteredCountries.unshift(india);
      this.countries = this.filteredCountries;
      if (localStorage.getItem("selectedCountry")) {
        this.selectedCountry = JSON.parse(
          localStorage.getItem("selectedCountry")
        );
        this.selectedCountryAvailable = true;
      } else {
        this.getDefaultCountry(this.countries);
      }
    } else {
      this.getAllCountries();
    }
  }

  handleAirportGlobalSearch(airportName) {
    console.log(airportName.target.value);
    let searchQuery = airportName.target.value.trim();
    this.airportService.getAirports(searchQuery).subscribe((response: any) => {
      if(response) {
        this.searchedAirports = response.data;
      }
    })
  }

  getAllCountries() {
    if (
      !localStorage.getItem("selectedCountry") ||
      (localStorage.getItem("selectedCountry") &&
        Object.keys(JSON.parse(localStorage.getItem("selectedCountry")))
          .length == 0)
    ) {
      this.apiService.getAllCountries().subscribe((res: any) => {
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          this.countries = res.data;
          this.getDefaultCountry(res.data);
          localStorage.setItem("countries", JSON.stringify(res.data));
        }
      });
    }
  }

  getDefaultCountry(countries) {
    let filteredCountry = countries.filter((x) => {
      return x.attributes.name == "India";
    });
    if (filteredCountry.length > 0) {
      let selectedCountry = filteredCountry[0];
      let mobile_length = selectedCountry.attributes.mobile_length.split("...");
      selectedCountry.attributes.min_mobile_digit = parseInt(mobile_length[0]);
      selectedCountry.attributes.max_mobile_digit = parseInt(mobile_length[1]) - 1;
      localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry));
      this.selectedCountry = selectedCountry;
      this.updateMobileValidation();
      this.selectedCountryAvailable = true;
    }
  }

  updateMobileValidation() {
    this.serviceForm.controls.traveller_mobile.addValidators(Validators.minLength(this.selectedCountry.attributes.min_mobile_digit));
    this.serviceForm.controls.traveller_mobile.addValidators(Validators.maxLength(this.selectedCountry.attributes.max_mobile_digit));
  }

  filterItem(searchTerm) {
    if (searchTerm && searchTerm.length > 0) {
      this.filteredCountries = Object.assign([], this.countries).filter(
        (item) =>
          item.attributes.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >
          -1
      );
    } else {
      this.filteredCountries = this.countries;
    }
  }

  setCountry(country) {
    this.selectedCountry = country;
    this.updateMobileValidation();
  }

  getDepartureDateTime() {
    let time = this.serviceForm.controls['journey_hour'].value + ":" + this.serviceForm.controls['journey_mins'].value + " " + this.serviceForm.controls['journey_time_type'].value
    return moment(new Date(moment(this.serviceForm.controls.journey_date.value, 'DD/MM/YYYY').format('MM/DD/YYYY') + " "+time)).format('YYYY-MM-DD[T]HH:mm:ss.000')
  }

  getServiceDateTime() {
    let searchAvailabilityForm = sessionStorage.getItem("searchAvailabilityForm") ? JSON.parse(sessionStorage.getItem("searchAvailabilityForm")) : {};
    if(searchAvailabilityForm && Object.keys(searchAvailabilityForm).length > 0) {
      let time = searchAvailabilityForm.hour + ":" + searchAvailabilityForm.mins + " " + searchAvailabilityForm.time_type;
      return moment(new Date(moment(searchAvailabilityForm.date).format("MM/DD/YYYY") + " "+time)).format("YYYY-MM-DD[T]HH:mm:ss.000");
    }
  }

  selectOriginAirport(airport) {
    if(this.selectedJourney == this.airportService.objectives.ARRIVAL && airport.id != this.selectedDestinationAirport.id) {
      this.selectedOriginAirport = airport;
      this.serviceForm.controls.flight_origin.setValue(airport.airport_name);
    }
  }

  selectDestinationAirport(airport) {
    if(this.selectedJourney == this.airportService.objectives.DEPARTURE && airport.id != this.selectedOriginAirport.id) {
      this.selectedDestinationAirport = airport;
      this.serviceForm.controls.flight_destination.setValue(airport.airport_name);
    }
  }

  handleSubmit() {
    this.formSubmited = true;
    let selectedAirport: any = JSON.parse(sessionStorage.getItem("selectedAirport"));
    let service_objective = sessionStorage.getItem("service_objective");
    let selectedTerminal: any = JSON.parse(sessionStorage.getItem("selectedTerminal"));
    if(this.serviceForm.valid && selectedTerminal && !this.isSavingServiceForm) {
      this.isSavingServiceForm = true;
      this.airportService.showInvalidTerminalSelected.emit(false);
      let payload = {
        airport_id: selectedAirport.id,
        service_date_time: this.getServiceDateTime(),
        adults: this.serviceForm.controls.adults.value,
        children: this.serviceForm.controls.children.value,
        infants: this.serviceForm.controls.infants.value,
        journey_type: service_objective, 
        origin_airport_code: this.selectedOriginAirport.airport_code,
        user_details: {
          adult: [
            {
              "lead": true,
              "pnr": this.serviceForm.controls['pnr_number'].value,
              "class": this.serviceForm.controls['travel_class'].value,
              "details": {
                  "name": {
                    "title": this.serviceForm.controls['traveller_title'].value,
                    "forename": this.serviceForm.controls['traveller_first_name'].value,
                    "surname": this.serviceForm.controls['traveller_last_name'].value,
                  },
                  "date_of_birth": moment(this.serviceForm.controls['traveller_date_of_birth'].value).format('YYYY-MM-DD') ,
                  "contacts": {
                      "emails": [
                          {
                              "type": "Main",
                              "email": this.serviceForm.controls['traveller_email'].value
                          }
                      ],
                      "phones": [
                          {
                              "type": "Main",
                              "name": "Main",
                              "phone": '+'+this.selectedCountry.attributes.calling_codes[0]+this.serviceForm.controls['traveller_mobile'].value
                          }
                      ]
                  }
              }
          }
          ],
          infant: [],
          child: [],
        },
        bags: {
          small: this.serviceForm.controls.small.value,
          medium: this.serviceForm.controls.medium.value,
          large: this.serviceForm.controls.large.value
        }
      };
      if(service_objective == this.airportService.objectives.DEPARTURE) {
        payload["departure_date_time"] = this.getDepartureDateTime();
        payload["destination_airport_code"] = this.selectedDestinationAirport?.airport_code;
        payload["departure_terminal_id"] = selectedTerminal.terminal_id;
        payload["departure_flight_no"] = this.serviceForm.controls.flight_number.value;
      } else {
        payload["arrival_flight_no"] = this.serviceForm.controls.flight_number.value;
        payload["arrival_terminal_id"] = selectedTerminal.terminal_id;
      }
      this.airportService.saveServiceDetails(payload).subscribe((response: any) => {
        if(response.status == 'success') {
          sessionStorage.setItem("booking_id", response.booking_id);
          this.airportService.booking_id = response.booking_id;
          this.failureMsg = "";
          this.showTransportServiceForm.emit();
        } else {
          this.failedToSave = true;
          this.toastr.warning("Something went wrong, please try again later.");
        }
        this.isSavingServiceForm = false;
      }, err => {
        this.isSavingServiceForm = false;
        this.failedToSave = true;
        this.failureMsg = err?.error?.msg;
      })
    } else {
      this.isSavingServiceForm = false;
      this.airportService.showInvalidTerminalSelected.emit(!selectedTerminal);
    }
  }

  updateJourneyType() {
    this.selectedJourney = this.airportService.objectives.DEPARTURE == this.selectedJourney ? this.airportService.objectives.ARRIVAL : this.airportService.objectives.DEPARTURE;
    sessionStorage.setItem("service_objective", this.selectedJourney);
  }

  update(type, key, etype){
    if (type === 'add'){
      if (etype === 'person') {
        
        if (key === 'adults' || key === 'children'){
          if ((this.serviceForm.controls['adults'].value + this.serviceForm.controls['children'].value) < (this.maxTravellers)){
            this.serviceForm.get(key).patchValue(this.serviceForm.controls[key].value + 1);
          }
          else{
            this.toastr.error(`Adult plus children cannot exceed ${this.maxTravellers}`);
          }
        }
        else{
          if (this.serviceForm.controls[key].value < this.serviceForm.controls['adults'].value){
            this.serviceForm.get(key).patchValue(this.serviceForm.controls[key].value + 1);
          }
          else{
            this.toastr.error(`Infant cannot exceed Adults`);
          }
        }
       
      }
      else{
        this.serviceForm.get(key).patchValue(this.serviceForm.controls[key].value + 1);
      }
    }
    else{
      if (this.serviceForm.controls[key].value > 0) {
        this.serviceForm.get(key).patchValue(this.serviceForm.controls[key].value - 1);
      }
    }
  }

  removeLetter(str) {
    if (parseInt(str) < 1) {
      str = "";
      this.serviceForm.controls["traveller_mobile"].setValue("");
    }
    this.serviceForm.controls["traveller_mobile"].setValue(str.replace(/\D/g, ""));
  }

}
