<div class="d-flex flex-column claimed-reward-card pad-24 mar-bot-20" [ngClass]="{'expired': redemption.isExpired}" [ngStyle]="{background: !redemption.isExpired ? globalConstants.data?.redemptionHistoryStyle?.success?.cardBackgroundColor : globalConstants.data?.redemptionHistoryStyle?.not_success?.cardBackgroundColor}">
    <div class="d-flex flex-row merchant-and-status w100 pad-16" [ngStyle]="{background: !redemption.isExpired ? globalConstants.data?.redemptionHistoryStyle?.success?.statusBackgroundColor : globalConstants.data?.redemptionHistoryStyle?.not_success?.statusBackgroundColor}">
        <img [src]="redemption.merchant_logo" class="merchant-logo" />
        <div class="d-flex flex-row justify-content-between w100">
            <div class="d-flex flex-column mar-left-10">
                <div class="fnt-16 f700 cfff">{{redemption.merchant_name}}</div>
                <div class="fnt-12 f500 offer-title">{{redemption.membership_name}}</div>
            </div>
            <div class="status pad-left-10 pad-right-10 mt-auto mb-auto text-center" [ngClass]="{'status-completed': (redemption.status == 'success' && !redemption.isExpired), 'status-expired': redemption.isExpired }">
                {{redemption.isExpired ? 'expired' : redemption.status}}
            </div>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-between w100 pad-16 pad-xs-left-0 pad-xs-right-0 mar-top-20 pad-top-0 pb-0">
        <div class="coupon-box d-flex flex-row align-items-center cfff pad-10" [ngClass]="{'justify-content-center': !redemption.voucher}">
            <ng-container *ngIf="redemption.voucher">
                <div class="code-label mt-auto mb-auto text-nowrap">Code :</div>
                <div class="d-flex flex-row justify-content-between w100">
                    <div class="fnt-14 f700 mar-left-5 white-variant-light">{{redemption.voucher}}</div>
                    <div (click)="copy.emit(redemption.voucher)" class="mar-top-2 pointer">
                        <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M9.57895 0H1.36842C0.615789 0 0 0.654545 0 1.45455V11.6364H1.36842V1.45455H9.57895V0ZM8.89474 2.90909H4.10526C3.35263 2.90909 2.74368 3.56364 2.74368 4.36364L2.73684 14.5455C2.73684 15.3455 3.34579 16 4.09842 16H11.6316C12.3842 16 13 15.3455 13 14.5455V7.27273L8.89474 2.90909ZM4.10526 14.5455V4.36364H8.21053V8H11.6316V14.5455H4.10526Z"
                            fill="#3395FF"/>
                        </svg>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!redemption.voucher">
                <div class="fnt-14 f700 white-variant-light text-center">
                    No Voucher code required
                </div>
            </ng-container>
        </div>
        <button class="btn custom-button" [ngClass]="{'disabled': redemption.isExpired, 'primary': !redemption.isExpired, 'fnt-12': deviceService.isMobile }" (click)="goTo.emit(redemption)">Redeem Now</button>
    </div>
    <div class="custom-border top d-flex justify-content-between flex-row fnt-14 f400 white-variant-light mar-top-20 pad-16 pad-xs-10 pb-0 pad-xs-left-0 pad-xs-right-0">
        <div class="validity-date d-flex">Valid till:<div class="mar-left-5">{{redemption.validity}}</div></div>
        <div class="blue-text fnt-14 f700 pointer" (click)="redirectToMerchantTerms.emit(redemption)"><u>Terms & Conditions</u></div>
    </div>
    <div class="mar-top-20 pad-16 custom-border top d-flex flex-row pb-0 pad-xs-left-0 pad-xs-right-0">
        <img src="../../../assets/icons/warning.svg"/>
        <div class="warning warning-text fnt-12 f400 mar-left-10">
            {{redemption.voucher ? 'Please use your Coupon Code at checkout in merchant app/site. In case of in-store usage, present the Coupon during billing to redeem.' : 'Offer will be applied directly on the merchant landing page.'}}
        </div>
    </div>
</div>