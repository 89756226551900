<div class="d-flex flex-column align-items-center">
    <img src="../../../../../../assets/icons/yellow-warning.svg" class="warning-icon" />
    <div class="fnt-16 f700 cfff">
        Your progress will be lost . Do you still want to go back ?
    </div>
    <div class="d-flex flex-row mar-top-39">
        <button class="btn custom-button primary outline" (click)="handleClose()">
            Cancel
        </button>
        <button class="btn custom-button primary mar-left-30" (click)="handleConfirm()">
            Yes
        </button>
    </div>
</div>