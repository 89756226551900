<div class="relative d-flex flex-column">
    <div class="d-flex flex-row pad-bot-16 pad-top-16 pad-left-24 pad-right-24">
        <div class="fnt-24 white-text f600">
            Contact Details
        </div>
        <img src="../../../../assets/icons/close.svg" class="close-icon mt-auto mb-auto pointer" (click)="closeModal()" />
    </div>
    <div class="pad-left-24 pad-right-24 pad-top-10 pad-bot-24">
        <form [formGroup]="contactForm" (ngSubmit)="handleSubmit()">
            <div class="row">
                <div class="col-md-6 col-12 d-flex flex-column pad-bot-20">
                    <div class="fnt-12">
                        First Name
                    </div>
                    <div class="input-container mar-top-10 pad-left-10">
                        <input formControlName="first_name" class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text" placeholder="First Name" />
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex flex-column pad-bot-20">
                    <div class="fnt-12">
                        Last Name
                    </div>
                    <div class="input-container mar-top-10 pad-left-10">
                        <input formControlName="last_name" class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text" placeholder="Last Name" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12 d-flex flex-column pad-bot-20">
                    <div class="fnt-12">
                        Mobile Number
                    </div>
                    <div class="input-container mar-top-10 pad-left-10">
                        <input formControlName="mobile" class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="number" placeholder="Mobile Number" />
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex flex-column pad-bot-20">
                    <div class="fnt-12">
                        Email Address
                    </div>
                    <div class="input-container mar-top-10 pad-left-10">
                        <input formControlName="email" class="form-control fnt-13 h100 w100 border-0 cfff bg-transparent" type="text" placeholder="Email" />
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row mar-bot-10 mar-top-10">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="#EEA841" xmlns="http://www.w3.org/2000/svg" class="mt-auto mb-auto pad-bot-3">
                    <path d="M11.3469 16.7165C10.7946 16.7166 10.3468 16.269 10.3467 15.7167L10.3461 11.7167C10.346 11.1644 10.7936 10.7166 11.3459 10.7165C11.8982 10.7165 12.346 11.1641 12.3461 11.7164L12.3467 15.7164C12.3468 16.2687 11.8991 16.7165 11.3469 16.7165Z"/>
                    <path d="M11.3453 6.71654C10.793 6.71663 10.3454 7.16441 10.3455 7.7167C10.3455 8.26898 10.7933 8.71663 11.3456 8.71654C11.8979 8.71646 12.3455 8.26867 12.3455 7.71639C12.3454 7.16411 11.8976 6.71646 11.3453 6.71654Z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3461 11.7148C22.347 17.79 17.4229 22.7156 11.3478 22.7165C5.27265 22.7175 0.347019 17.7934 0.34608 11.7182C0.345142 5.64311 5.26925 0.717483 11.3444 0.716544C17.4195 0.715606 22.3451 5.63971 22.3461 11.7148ZM11.3475 20.7165C16.318 20.7158 20.3468 16.6857 20.3461 11.7152C20.3453 6.74459 16.3153 2.71578 11.3447 2.71654C6.37413 2.71731 2.34531 6.74737 2.34608 11.7179C2.34685 16.6885 6.37691 20.7173 11.3475 20.7165Z"/>
                </svg>                    
                <div class="mar-left-5 fnt-12 note">
                    Membership details will be shared to this contact via SMS and e-mail. 
                </div>
            </div>
            <button class="custom-button primary pad-top-10 pad-bot-10 border-0 pad-left-15 pad-right-15 mar-top-10 d-flex" type="submit">
                Confirm & Claim
                <div class="small-loader mt-auto mb-auto mar-left-8" *ngIf="updatingContactDetails"></div>
            </button>
        </form>
    </div>
</div>