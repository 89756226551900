import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class GlobalConstants {

    client: string;
    data: any;
    isFounderCard: boolean = false;
    isConcierge: boolean = false;

    priorityChecInIds = {
        sandbox: ["310546cd-e228-4c7d-b05d-6fa468d6cfcf"],
        prod: ["fa599ed6-eb22-4588-ae21-dc36ea47bb03"]
    };

    theKen = {
        sandbox: "",
        prod: ["1e4105c1-1200-4e45-b0b1-811d8adf35d4"]
    }
    
    airportLoungeIds = {
        sandbox: ["3550f5d5-f970-459b-a7ef-15da220dfbd0"],
        prod: ["8fa4bef0-cd16-4557-a312-0ea24c9feeb8"]
    };

    businessCardConfig = {
        hideHomepage: true,
        hideMilestoneRewards: true,
        portalLink: "https://x.razorpay.com/cards",
        colors: {
            headerByColor: "#080D29",
            couponModalBgColor: "#1F2440",
            buttonActive: "#242A4A",
            buttonInactive: "#4F546E",
            blueColor: "#5CA2F7",
            detailsPageBg: "#161D40",
            subHeaderMobile: "#1E2442"
        },
        offerCardStyle: {
            cardBackgroundColor: "#242A4A",
            titleColor: "#FDFDFD",
            descriptionColor: "#BBBDC8",
            buttonActive: "#4F546E",
            buttonInactive: "#242A4A",
            boxShadow: "0px 7px 6px rgba(33, 34, 44, 0.25)",
            moreTagTextColor: "#FDFDFD",
            moreTagBgColor: "linear-gradient(90deg, #DF8700 0%, #DF6B00 100%)",
            moretagArrowColor: "#242A4A",
            showKnowMore: true,
            buttonFontSize: '14px',
            showKnowMoreBg: '#242A4A'
        },
        featuredCardStyling: {
            cardBackgroundColor: "linear-gradient(109.85deg, rgb(1, 17, 79), rgb(18, 16, 43), rgb(34, 28, 45))",
            titleColor: "#FDFDFD",
            descriptionColor: "#BBBDC8",
            buttonActive: "#1566F1",
            buttonInactive: "#1566F1",
            buttonBorderColor: "",
            boxShadow: "inset rgb(1 17 79 / 62%) 0px 10px 20px 0px",
            moreTagTextColor: "#FDFDFD",
            moreTagBgColor: "linear-gradient(90deg, #DF8700 0%, #DF6B00 100%)",
            moretagArrowColor: "#092149",
            showKnowMore: true,
            buttonFontSize: '14px',
            showKnowMoreBg: 'linear-gradient(134deg, #17132B, #1C172C)'
        },
        footer: {
            footer1Bg: "#111739",
            footer2Bg: "#161D40"
        },
        x_header: {
            prod: {
              client_id: "8472fcf3-5a9a-413a-92c0-9b430506ecb2",
              project_id: "e5c5f613-822c-4a11-9af5-a1af6405c63f",
            },
            sandbox: {
                client_id: "d6e70ecf-1ddb-413c-993d-6a0be53fa836",
                project_id: "c15ffefb-1e7f-4290-9d2f-88b6b80de735",
            },
            default_card_type: "credit",
        },
    };

    founderCardConfig = {
        portalLink: "https://x.razorpay.com/cards/founder/rewards",
        colors: {
            headerByColor: "#111111",
            couponModalBgColor: "linear-gradient(146.01deg, #262626 -2.69%, #1C1B1B 85.64%), #FBFBFB",
            buttonActive: "#242A4A",
            buttonInactive: "#4F546E",
            blueColor: "#5CA2F7",
            bodyBgColor: "#111111",
            detailsPageBg: "linear-gradient(142.04deg, #262626 -2.92%, #1C1B1B 93.19%), #232323",
            subHeaderMobile: "linear-gradient(180deg, #232323 -10.56%, #101010 16.75%, #111111 100%)"
        },
        offerCardStyle: {
            cardBackgroundColor: "#141414",
            titleColor: "#FDFDFD",
            descriptionColor: "#BBBDC8",
            buttonActive: "#0000001a",
            buttonInactive: "#2d2d2f",
            boxShadow: "0px 7px 6px rgba(33, 34, 44, 0.25)",
            moreTagTextColor: "#FDFDFD",
            moreTagBgColor: "linear-gradient(90deg, #DF8700 0%, #DF6B00 100%)",
            moretagArrowColor: "#242A4A",
            showKnowMore: true,
            buttonFontSize: '14px',
            showKnowMoreBg: '#242A4A'
        },
        featuredCardStyling: {
            cardBackgroundColor: "linear-gradient(109.85deg, rgb(1, 17, 79), rgb(18, 16, 43), rgb(34, 28, 45))",
            titleColor: "#FDFDFD",
            descriptionColor: "#BBBDC8",
            buttonActive: "#1566F1",
            buttonInactive: "#1566F1",
            buttonBorderColor: "",
            boxShadow: "inset rgb(1 17 79 / 62%) 0px 10px 20px 0px",
            moreTagTextColor: "#FDFDFD",
            moreTagBgColor: "linear-gradient(90deg, #DF8700 0%, #DF6B00 100%)",
            moretagArrowColor: "#092149",
            showKnowMore: true,
            buttonFontSize: '14px',
            showKnowMoreBg: 'linear-gradient(134deg, #17132B, #1C172C)'
        },
        modalStyling: {
            backgroundColor: "#C4C4C4",
            footerBg: "#111111",
            headerBg: " linear-gradient(180deg, rgba(255, 255, 255, 0) -1.54%, rgba(255, 255, 255, 0.04) 102.47%), linear-gradient(102.78deg, rgba(187, 222, 255, 0) 36.12%, rgba(231, 242, 252, 0) 59.24%, rgba(216, 234, 250, 0) 78.57%, rgba(10, 97, 177, 0.124) 102.39%)"
        },
        redemptionHistoryStyle: {
            success: {
                cardBackgroundColor: "#1F1F1F",
                statusBackgroundColor: "linear-gradient(0deg, #111111, #111111), linear-gradient(180deg, rgba(75, 126, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(90.47deg, rgba(98, 180, 255, 0.072) 6.12%, rgba(234, 243, 255, 0.096) 100.49%)" ,
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.08), inset 0px 0px 12px rgba(0, 0, 0, 0.32)",
                backdropFilter: "blur(24px)"
            },
            not_success: {
                cardBackgroundColor: "#2A2E32",
                statusBackgroundColor: "linear-gradient(273.37deg, rgba(17, 17, 17, 0.32) 6.09%, rgba(17, 17, 17, 0.1056) 69.93%), linear-gradient(180deg, rgba(31, 31, 31, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(90.47deg, rgba(98, 180, 255, 0.072) 6.12%, rgba(234, 243, 255, 0.096) 100.49%)",
            }
        },
        footer: {
            footer1Bg: "linear-gradient(142.04deg, #262626 -2.92%, #1C1B1B 93.19%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.53) 100%)",
            footer2Bg: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.53) 100%)"
        },
        x_header: {
            prod: {
              client_id: "8472fcf3-5a9a-413a-92c0-9b430506ecb2",
              project_id: "384dab6b-c9a2-4261-961e-d2ae3195ade8",
            },
            sandbox: {
                client_id: "d6e70ecf-1ddb-413c-993d-6a0be53fa836",
                project_id: "528dcada-03b8-4a61-b0cb-640d28e4d770",
            },
            default_card_type: "credit",
        },
    };

    constructor(@Inject(DOCUMENT) private document: any) {
        let subDomain = this.document.location.hostname.split("www.").join("").split(".", 1);
        subDomain = subDomain.join("").split("sandbox-").join("").split(".", 1).shift();
        if(subDomain.includes("concierge")) {
            this.isConcierge = true;
        }
        if(subDomain.includes("founder")) {
            this.changeRootVariables();
            this.data = this.founderCardConfig;
            this.isFounderCard = true;
            this.client = "Founder";
        } else {
            this.data = this.businessCardConfig;
            this.isFounderCard = false;
            this.client = "Business";
        }

        

    }

    changeRootVariables() {
        var r: any = document.querySelector(':root');
        r.style.setProperty('--primaryDarkBg', this.founderCardConfig.colors.headerByColor);
        r.style.setProperty('--bodyBg', this.founderCardConfig.colors.bodyBgColor);
    }
}
