import { DeviceService } from './../../../../../common/services/device.service';
import { AirportService } from 'src/app/common/services/airport.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

class Vehicle {
  type: string = "";
  pax: string = "";
  bags: string = "";
  name: string = "";

    constructor(type, pax, bags, name) {
      this.type = type;
      this.pax = pax;
      this.bags = bags;
      this.name = name;
    }
}

@Component({
  selector: 'app-transport-service',
  templateUrl: './transport-service.component.html',
  styleUrls: ['./transport-service.component.scss']
})
export class TransportServiceComponent implements OnInit {

  distances;
  selectedDistance ;
  isDropdown = false;
  service;
  vehicles = [];
  updatedVehicles = [];
  selectedVehicle;
  selectedVehicleIndex = 0;
  selectedVehicleCount = 0;
  service_objective = sessionStorage.getItem('service_objective')
  showMsg = false;
  updatingService: boolean = false;

  @Output() nextStep = new EventEmitter<any>();
  constructor(
    private toastr: ToastrService,
    private airportService: AirportService,
    public deviceService: DeviceService
  ) { }

  ngOnInit(): void {
    this.checkIsDropdown();
    this.setVehicles();

  }

  getVehicleData (vehicle) {
    if(vehicle.toLowerCase().includes("sedan")) {
      return new Vehicle("sedan", "5", "2", "Toyota Etios");
    } else if(vehicle.toLowerCase().includes("mpv")) {
      return new Vehicle("mpv", "8", "6", "Toyota Innova");
    } if(vehicle.toLowerCase().includes("suv")) {
      return new Vehicle("suv", "6", "4", "Maruti Suzuki Ertiga");
    }
  }
  
  checkIsDropdown() {
    if(sessionStorage.getItem('selectedTerminal')) {
      const selectedTerminal  = JSON.parse(sessionStorage.getItem('selectedTerminal'))
      if(selectedTerminal) {
        this.service = selectedTerminal?.services?.find(service => service.service_name === 'Car Transfer India' || service.service_name === 'Car Transfer');
      }
      // this.isDropdown = this.service.service_name === 'Car Transfer' ? true : false;

      // if(this.isDropdown) {
      //   this.distances = this.service.fields.find(field => field.name === 'distance')?.validation?.values;
      //   this.selectDisance(this.distances[0]);
      // }
    }
  }

  setVehicles() {
    if(this.service && this.service.fields) {
      this.vehicles = this.service.fields.find(field => field.name === 'vehicle_type')?.validation?.values;
      this.updatedVehicles = this.vehicles.map(vehicle => {
        const frstSplt = vehicle.split('(');
        const scndSplit = frstSplt[1].split(',');
        return this.getVehicleData(vehicle);
      })
    }
  }

  selectDisance(distance) {
    this.selectedDistance = distance;
  }

  selectVehicle(event, vehicle, i) {
    this.selectedVehicle = vehicle;
    this.selectedVehicleIndex = i;
    this.selectedVehicleCount = 1;
  }

  updateVehicleCount(event) {
    this.selectedVehicleCount = event;
  }

  updateService() {
    const req = {
      booking_id: sessionStorage.getItem('booking_id'),
      requested_service_attributes: {
        gac_service_id: this.service.service_id,
        fields: [
          {
            name: "num_of_c",
            value: this.selectedVehicleCount
          },
          {
            name: "vehicle_type",
            value: this.vehicles[this.selectedVehicleIndex]
          },
          {
            name: "distance",
            value: this.selectedDistance
          },
          {
            name: "frrom_postcode",
            value: this.service_objective == 'departure' ? JSON.parse(sessionStorage.getItem('searchAvailabilityForm'))?.address ?? '' : JSON.parse(sessionStorage.getItem('searchAvailabilityForm'))?.airport ?? ''
          },

          {
            name: "to_postcode",
            value: this.service_objective == 'departure' ? JSON.parse(sessionStorage.getItem('searchAvailabilityForm'))?.airport ?? '' : JSON.parse(sessionStorage.getItem('searchAvailabilityForm'))?.address ?? ''
          },
        ]
      }
    }
    if(req.booking_id && req.requested_service_attributes.gac_service_id && this.selectedVehicle && this.selectedDistance && !this.updatingService) {
      this.updatingService = true;
      this.airportService.updateGacService(req).subscribe((res:any) => {
        if(res?.data?.id) {
          this.nextStep.emit();
        } else {
          this.toastr.error('Booking not created! Please try again.')
        }
        this.updatingService = false;
      },
      (err) => {
        this.updatingService = false;
        this.toastr.error(err?.error?.msg ?? 'Something went wrong! Please try again later');
      })
    } else {
      this.updatingService = false;
      this.showMsg = true;
      if(!req.booking_id) {
        this.toastr.error('Booking not created, please complete previous steps.')
      } else if(!req.requested_service_attributes.gac_service_id) {
        this.toastr.error('Service not selected, please complete previous steps.')
      } else if(!this.selectedVehicle) {
        this.toastr.error('Please select vehicle type.')
      }
    }
  }

}
