<div class="d-flex flex-column grey2-container">
    <div class="booking-header mar-top-8 d-flex flex-row pad-left-20 pad-right-20 pad-top-12 pad-bot-12 align-items-center">
        <img src="../../../../../../assets/icons/summary.svg" class="summary-icon mar-right-10"/>
        <div class="f400" [ngClass]="{'fnt-14': deviceService.isMobile, 'fnt-18': !deviceService.isMobile}">
            Booking Summary
        </div>
    </div>
    <div class="d-flex flex-column pad-top-5 pad-bot-5">
        <div class="d-flex flex-column pad-left-20 pad-right-20 pad-top-10 pad-bot-10 cfff">
            <div class="f100 fnt-12">
                Trip Type
            </div>
            <div class="f500 mar-top-3 text-capitalize">
                {{tripType}}
            </div>
        </div>
        <div class="d-flex flex-column pad-left-20 pad-right-20 pad-top-10 pad-bot-10 cfff">
            <div class="f100 fnt-12">
                Pickup Address
            </div>
            <div class="f500 mar-top-3 text-capitalize">
                {{searchAvailability.address}}
            </div>
        </div>
        <div class="d-flex flex-column pad-left-20 pad-right-20 pad-top-10 pad-bot-10 cfff">
            <div class="f100 fnt-12">
                Airport
            </div>
            <div class="f500 mar-top-3 text-capitalize">
                {{selectedAirport.airport_name}}
            </div>
            <div class="fnt-12 f300 text-capitalize">
                {{selectedAirport.city}} {{selectedTerminal?.terminal_name ? '| '+selectedTerminal?.terminal_name : ''}}
            </div>
        </div>
        <div class="d-flex flex-column pad-left-20 pad-right-20 pad-top-10 pad-bot-10 cfff">
            <div class="f100 fnt-12">
                Pickup Date
            </div>
            <div class="f500 mar-top-3 text-capitalize">
                {{pickUpDate}}
            </div>
        </div>
        <div class="d-flex flex-column pad-left-20 pad-right-20 pad-top-10 pad-bot-10 cfff">
            <div class="f100 fnt-12">
                Pickup Time
            </div>
            <div class="f500 mar-top-3 text-uppercase">
                {{pickUpTime}}
            </div>
        </div>
    </div>

</div>