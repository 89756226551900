import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { ActivatedRoute } from '@angular/router';
import { MerchantsService } from 'src/app/common/services/merchants.service';
import { HttpRequest } from '@angular/common/http';
import { Subscription } from 'rxjs';
import  {OfferCardClass} from 'oms-stencil-components/dist/collection/classes/OfferCard';

@Component({
  selector: 'app-category-based-rewards',
  templateUrl: './category-based-rewards.component.html',
  styleUrls: ['./category-based-rewards.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }],
  host: {
    '(document:click)': 'onClick($event)',
    '(document:scroll)': 'onScroll($event)',
  },
})
export class CategoryBasedRewardsComponent implements OnInit {

  categories: Array<any> = [];
  inViewCategories: Array<any> = [];
  inDropdownCategories: Array<any> = [];
  categoryBasedMerchants: Array<any> = [];
  showDropdown: boolean = false;
  showMore: boolean = false;
  categoriesLoading: boolean = false;
  merchantsLoading: boolean = false;
  failedToGetMerchants: boolean = false;
  selectedCategory: any;
  getMerchantsRequest: Subscription;
  categorySelectionSubscription: Subscription;
  cardStyle: OfferCardClass;

  @ViewChild('dropdownRef') dropdownRef: ElementRef;
  @ViewChild('dropdownMenu') dropdownMenu: ElementRef;

  categoryIcons = {
    productivity: "../../../../../assets/icons/productivity.svg",
    marketing: "../../../../../assets/icons/marketing.svg",
    'cloud-infrastructure': "../../../../../assets/icons/cloud-infrastructure.svg",
    'small-business': "../../../../../assets/icons/small-business.svg",
    'travel': "../../../../../assets/icons/travel.svg",
    'coworking-spaces': "../../../../../assets/icons/coworking-spaces.svg",
    'logistics': "../../../../../assets/icons/logistics.svg",
    'entertainment': "../../../../../assets/icons/entertainment.svg",
    'education': "../../../../../assets/icons/education.svg",
    'shopping': "../../../../../assets/icons/shopping.svg"
  }

  constructor(
    private apiService: ApiService,
    public globalConstants: GlobalConstants,
    private route: ActivatedRoute,
    private merchantSerive: MerchantsService
  ) {
    this.claimOffer = this.claimOffer.bind(this);
    this.categorySelectionSubscription = this.merchantSerive.selectedCategory.subscribe((category) => {
      this.selectedCategory = category;
      this.getMerchants();
    });

    this.cardStyle = new OfferCardClass();
    this.cardStyle.style.background_color = this.globalConstants.data.offerCardStyle.cardBackgroundColor;
    this.cardStyle.merchant.color = this.globalConstants.data.offerCardStyle.titleColor;
    this.cardStyle.offer.color = this.globalConstants.data.offerCardStyle.descriptionColor;
    this.cardStyle.button.button_active_color = this.globalConstants.data.offerCardStyle.buttonActive;
    this.cardStyle.button.button_inactive_color = this.globalConstants.data.offerCardStyle.buttonInactive;
    this.cardStyle.showKnowMore.background_color = this.globalConstants.data.offerCardStyle.showKnowMoreBg;
    this.cardStyle.offer_count_tag.text_color = this.globalConstants.data.offerCardStyle.moreTagTextColor;
    this.cardStyle.offer_count_tag.background_color = this.globalConstants.data.offerCardStyle.moreTagBgColor;
    this.cardStyle.offer_count_tag.arrow_color = this.globalConstants.data.offerCardStyle.moretagArrowColor;
    this.cardStyle.showKnowMore.show_know_more_text = this.globalConstants.data.offerCardStyle.showKnowMore;
    this.cardStyle.offer.show_image = false;
    this.cardStyle.style.box_shadow = "unset"
  }

  ngOnInit(): void {
    this.getCategories();
  }

  ngOnChanges(props) {
  }

  onClick(event) {
    if (this.dropdownRef && this.dropdownRef.nativeElement && !this.dropdownRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  onScroll(event) {
    if (this.dropdownRef && !this.dropdownRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  handleDropdownToggle() {
    this.showDropdown = !this.showDropdown;
    if(!this.showDropdown && this.dropdownMenu && this.dropdownMenu.nativeElement) {
      this.dropdownMenu.nativeElement.style.left = '';
      this.dropdownMenu.nativeElement.style.right = '';
      this.dropdownMenu.nativeElement.style.top = '';
    }
  }

  segmentOutCategories() {
    if(this.selectedCategory) {
      this.categories.unshift(this.selectedCategory);
    }
    this.inViewCategories = this.categories.length > 6 ? this.categories.slice(0,5) : this.categories;
    if(this.categories.length > 7) {
      this.inDropdownCategories = this.categories.slice(5, this.categories.length);
    }
  }

  getCategories() {
    if(this.merchantSerive.categories.length > 0) {
      this.merchantSerive.categories.map((category) => {
        this.categories.push(category);
      });
      this.segmentOutCategories();
      this.getMerchants();
    } else {
      this.categoriesLoading =  true;
      this.apiService.getCategories({benefit_type: "offers"}).subscribe((response: any) => {
        if(response.data.length > 0) {
          response.data.map((category) => {
            this.categories.push(category);
          });
          this.segmentOutCategories();
        }
        this.categoriesLoading =  false;
        this.getMerchants();
      }, error => {
        this.categoriesLoading =  false;
      }) 
    }
  }

  claimOffer(merchant) {
    this.apiService.claimOffer(merchant);
  }

  showAll() {
    this.selectedCategory = null;
    this.merchantSerive.selectedCategory.emit(null);
  }

  handleCategorySelect(category) {
    this.selectedCategory = category;
    this.merchantSerive.selectedCategory.emit(category);
  }

  handleCategoryFromDropdown(category, i) {
    let lastInViewCategory = this.inViewCategories[this.inViewCategories.length - 1];
    this.inViewCategories[this.inViewCategories.length - 1] = category;
    this.inDropdownCategories[i] = lastInViewCategory;
    this.selectedCategory = category;
    this.merchantSerive.selectedCategory.emit(category);
  }

  ngOnDestroy() {
    if(this.getMerchantsRequest) {
      this.getMerchantsRequest.unsubscribe();
    }
    if(this.categorySelectionSubscription) {
      this.categorySelectionSubscription.unsubscribe();
    }
  }

  getMerchants() {

    let categoryCode = this.selectedCategory ? this.selectedCategory.attributes.code : 'all';
    if(this.merchantSerive.getMerchantsBy(categoryCode).length > 0) {
      this.categoryBasedMerchants = this.merchantSerive.getMerchantsBy(categoryCode);
    } else {
      let params = {}
      if(this.selectedCategory) {
        params["categories"] = this.selectedCategory.attributes.code
      }
      this.failedToGetMerchants = false;
      this.merchantsLoading = true;
      this.getMerchantsRequest = this.apiService.getMerchants(params).subscribe((response: any) => {
        if(response.data) {
          this.categoryBasedMerchants = response.data;
          this.merchantSerive.updateMerchantStore(categoryCode, this.categoryBasedMerchants);
        }
        this.merchantsLoading = false;
      }, error => {
        this.merchantsLoading = false;
        this.failedToGetMerchants = true;
      })
    }
  }

}
