<div class="relative d-flex flex-column">
    <img src="../../../../../assets/logos/logo.svg" class="logo" />

    <div class="page-loader" *ngIf="!selectedCountryAvailable && (stepToShow == 'mobile')"></div>
    <section *ngIf="stepToShow == 'mobile' && selectedCountryAvailable">
        <form [formGroup]="mobileForm" (submit)="userSearchByMobile()" autocomplete>
            <div class="row">
                <div class="col-sm-12">
                    <div class="cfff fnt-14 f400 mar-bot-16 mar-top-24">
                        To continue exploring offers curated for you
                    </div>
                    <div class="mar-bot-10 fnt-18 cfff">Authenticate your <div class="d-inline f700">mobile number</div></div>
                    <div class="mobile-block d-flex flex-row mar-top-32 focus-active-border pad-10 custom-border border-rounded-2 relative p-0" [ngClass]="{'warning warning-border': showMobileInvalidMessage }">
                        <div class="country-block d-flex allign-center justify-center searchdrop relative custom-border right" dropdown #dropdown="bs-dropdown" [insideClick]="true">
                            <div class="w100 h100 d-flex align-center pointer relative btn border-0" dropdownToggle>
                                <img [src]="selectedCountry?.attributes?.flag_url" alt="Flag" class="relative country-flag" *ngIf="selectedCountry" />
                                <div class="mar-left-5">
                                    <svg class="left" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 4L0 0L8 6.28116e-07L4 4Z" fill="#98A2B4" />
                                    </svg>
                                </div>
                            </div>
                            <div class="dropdown-menu dropdown-menu-shadow w100 country-list mar-top-6 p-2" *dropdownMenu>
                                <div class="select-input-parent pad-8 w100 z1 pb-0 pt-0">
                                <input autocomplete="new-password" class="select-input form-control fnt-13 bg-transparent border-0 cfff p-0" [(ngModel)] = "countrySearchTerm" [ngModelOptions]="{standalone: true}" (input) = "filterItem(countrySearchTerm)" placeholder="Search country">
                                </div>
                                <div class="block loc-results">
                                    <div *ngFor="let country of filteredCountries; let i = index" (click)="dropdown.isOpen = false;countrySearchTerm = '';setCountry(country);" [ngClass]="{'selected-country': country.attributes?.iso_code == selectedCountry.iso_code}">
                                    <div class="flex relative country-item pointer pad-10">
                                        <img [src]="country.attributes.flag_url" class="country-flag mt-auto mb-auto">
                                        <div class="item-name flex align-center cfff fnt-14 w100 mar-left-5">
                                            {{country.attributes.name}}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mobile-input flex w100">
                            <input formControlName="mobile" class="form-control h100 w100 border-0 cfff bg-transparent" [(ngModel)]="mobNum" type="text"
                                placeholder="Mobile Number" maxlength="{{selectedCountry?.attributes?.max_mobile_digit}}"
                                minlength="{{selectedCountry?.attributes?.min_mobile_digit}}"
                                (input)="removeLetter($event.target.value);onMobileChange()" 
                            />
                            <div class="small-loader input-load" *ngIf="searchMobileLoader"></div>
                            <img src="../../../../../assets/icons/warning.svg" class="warning-icon mt-auto mb-auto" *ngIf="showMobileInvalidMessage" />
                        </div>
                        <div class="warning warning-text fnt-12 f600 absolute" *ngIf="showMobileInvalidMessage">
                            Enter Valid Mobile Number
                        </div>
                    </div>

                    <div class="clearfix continue-btn-block mar-top-10">
                        <button class="relative custom-button primary border-rounded-2 w100 btn d-flex justify-content-center pad-10" type="submit"
                            *ngIf="isMobileNumberValid"
                            [disabled]="isMobileNumberSearching">
                            Continue <div class="small-loader merged-card-loader mar-left-10 mt-auto mb-auto" *ngIf="isMobileNumberSearching"></div></button>
                            <button type="btn" *ngIf="!isMobileNumberValid" class="custom-button disabled border-rounded-2 w100 btn pad-10" disabled="disabled">Continue</button>
                        </div>

                </div>
            </div>
        </form>
    </section>
    <section *ngIf="stepToShow == 'password'">
        <form [formGroup]="passwordForm" (submit)="handlePasswordSubmit()">
            <div class="cfff fnt-14 f400 mar-bot-16 mar-top-24">
                To continue exploring offers curated for you
            </div>
            <div class="fnt-16 f400 cfff mar-top-0 mar-bot-30">
                Authenticate with your password
            </div>
            <div class="d-flex flex-column mar-bot-60">
                <div class="warning relative pt-0 mar-bot-20 relative">
                    <div class="focus-active-border custom-border">
                        <input formControlName="password" class="form-control h100 w100 border-0 cfff bg-transparent" type="password" placeholder="Password" minlength="7" (input)="onPasswordEnter()" />
                    </div>
                    <div class="small-loader loading-position" *ngIf="passwordVerifying"></div>
                    <ng-container *ngIf="errorVerifyingPassword && !passwordVerifying">
                        <img src="../../../../../assets/icons/warning.svg" class="warning-icon mt-auto mb-auto" />
                        <div class="warning warning-text fnt-12 f600 absolute">
                            Incorrect Password! Please try again.
                        </div>
                    </ng-container>
                </div>
                <button class="btn custom-button" [ngClass]="{'primary': isPasswordValid, 'disabled': !isPasswordValid}" type="submit">
                    Continue
                </button>
            </div>
        </form>
    </section>
    <div class="footer-info d-flex flex-row mar-top-39 pad-top-10 pad-bot-10 pad-left-30 pad-right-30" [ngStyle]="{background: globalConstants?.data?.modalStyling?.footerBg}">
        <div class="fnt-12 cfff f400">
            Your Card/Account parameters are used only to check eligibility and are encrypted
        </div>
        <img src="../../../../../assets/icons/pci.svg" class="pci-complaint-icon mt-auto mb-auto" />
    </div>
</div>
