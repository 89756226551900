import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-concierge-login',
  templateUrl: './concierge-login.component.html',
  styleUrls: ['./concierge-login.component.scss']
})
export class ConciergeLoginComponent implements OnInit {

  errorVerifyingPassword: boolean = false;
  passwordVerifying: boolean = false;
  stepToShow: string = "password";
  isPasswordValid: boolean = false;
  selectedCountryAvailable: boolean =  false;
  selectedCountry: any;
  countries: Array<any> = [];
  filteredCountries: Array<{}> = [];
  countrySearchTerm: string = "";
  showMobileInvalidMessage: boolean = false;
  isMobileNumberValid: boolean = false;
  mobNum: string;
  searchMobileLoader: boolean = false;
  isMobileNumberSearching: boolean = false;
  mobileForm: any = this.formBuilder.group({
    mobile: ["", [Validators.required]]
  });
  passwordForm: any = this.formBuilder.group({
    password: ["", [Validators.required, Validators.minLength(7)]]
  });

  constructor(
    public globalConstants: GlobalConstants,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private matDialogRef: MatDialogRef<ConciergeLoginComponent>,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.authenticationService.isUserLoggedIn.subscribe((isLoggedIn) => {
      if(isLoggedIn) {
        this.matDialogRef.close(true);
      }
    })
    if(sessionStorage.getItem("concierge-token")) {
      this.stepToShow = "mobile";
    }
    if (localStorage.getItem("countries")) {
      this.countries = JSON.parse(localStorage.getItem("countries"));
      this.filteredCountries = this.countries;
      let india;
      this.filteredCountries = this.filteredCountries.filter((country: any) => {
        if(country.attributes.name.toLowerCase() != "india") {
          return true;
        } else {
          india = country;
          return false;
        }
      });
      this.filteredCountries.sort((a: any,b: any) => {
        if(a.attributes.name.charAt(0).toLowerCase() < b.attributes.name.charAt(0).toLowerCase()) { return -1; }
        if(a.attributes.name.charAt(0).toLowerCase() > b.attributes.name.charAt(0).toLowerCase()) { return 1; }
        return 0;
      });
      this.filteredCountries.unshift(india);
      this.countries = this.filteredCountries;
      if (localStorage.getItem("selectedCountry")) {
        this.selectedCountry = JSON.parse(
          localStorage.getItem("selectedCountry")
        );
        this.selectedCountryAvailable = true;
      } else {
        this.getDefaultCountry(this.countries);
      }
    } else {
      this.getAllCountries();
    }
  }

  ngOnInit(): void {
  }

  getAllCountries() {
    if (
      !localStorage.getItem("selectedCountry") ||
      (localStorage.getItem("selectedCountry") &&
        Object.keys(JSON.parse(localStorage.getItem("selectedCountry")))
          .length == 0)
    ) {
      this.apiService.getAllCountries().subscribe((res: any) => {
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          this.countries = res.data;
          this.getDefaultCountry(res.data);
          localStorage.setItem("countries", JSON.stringify(res.data));
        }
      });
    }
  }

  getDefaultCountry(countries) {
    let filteredCountry = countries.filter((x) => {
      return x.attributes.name == "India";
    });
    if (filteredCountry.length > 0) {
      let selectedCountry = filteredCountry[0];
      let mobile_length = selectedCountry.attributes.mobile_length.split("...");
      selectedCountry.attributes.min_mobile_digit = parseInt(mobile_length[0]);
      selectedCountry.attributes.max_mobile_digit = parseInt(mobile_length[1]) - 1;
      localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry));
      this.selectedCountry = selectedCountry;
      this.selectedCountryAvailable = true;
    }
  }

  filterItem(searchTerm) {
    if (searchTerm && searchTerm.length > 0) {
      this.filteredCountries = Object.assign([], this.countries).filter(
        (item) =>
          item.attributes.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >
          -1
      );
    } else {
      this.filteredCountries = this.countries;
    }
  }

  setCountry(country) {
    this.selectedCountry = country;
    this.onMobileChange();
  }

  removeLetter(str) {
    if (parseInt(str) < 1) {
      str = "";
      this.mobileForm.controls["mobile"].setValue("");
    }
    this.mobileForm.controls["mobile"].setValue(str.replace(/\D/g, ""));
  }

  handlePasswordSubmit() {
    if(this.passwordForm.valid && this.passwordForm.controls.password.valid) {
      this.passwordVerifying = true;
      this.apiService.conciergeLogin(this.passwordForm.controls.password.value).subscribe((response: any) => {
        if(response.success == true) {
          sessionStorage.setItem("concierge-token", response.token);
          this.errorVerifyingPassword = false;
          this.stepToShow = "mobile";
        } else {
          this.errorVerifyingPassword = true;
        }
        this.passwordVerifying = false;
      })
    } else {
      this.passwordVerifying = false;
    }
  }

  onPasswordEnter() {
    if(this.passwordForm.valid && this.passwordForm.controls.password.valid) {
      this.isPasswordValid = true;
    } else {
      this.isPasswordValid = false;
    }
  }

  userSearchByMobile() {
    let calling_code;
    if(this.selectedCountry?.attributes?.calling_codes) {
      calling_code = this.selectedCountry?.attributes?.calling_codes[0]
    }
    this.isMobileNumberSearching = true;
    this.apiService.validateUser(this.mobNum, calling_code).subscribe((response: any) => {
      let currentUser = {
        id: response.id,
        token: response.token,
      }
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.apiService.getUserDetails().subscribe(
        (res: any) => {
          localStorage.setItem("userDetails", JSON.stringify(res));
          this.authenticationService.isUserLoggedIn.emit(true);
          this.router.navigate(["/"]);
          this.isMobileNumberSearching = false;
        }, err => {
          this.toastr.warning("Failed to get user details, please try again later.")
          this.isMobileNumberSearching = false;
        }
      )
    })
  }

  onMobileChange() {
    if (
      this.mobileForm.value.mobile.length >=
        this.selectedCountry.attributes.min_mobile_digit &&
      this.mobileForm.value.mobile.length <=
        this.selectedCountry.attributes.max_mobile_digit
    ) {
      this.isMobileNumberValid = true;
    } else {
      this.isMobileNumberValid = false;
    }
  }

}
