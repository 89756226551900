<div class="pad-top-24 pad-bot-24" [ngClass]="{'pad-left-32 pad-right-32': !deviceService.isMobile, 'pad-left-16 pad-right-16': deviceService.isMobile}">
  <div class="flex-wrap d-flex" [ngClass]="{'justify-content-between': deviceService.isMobile}" *ngIf="!isReadOnly">
    <div class="d-flex" (click)="selectDeparture()">
      <input type="radio" name="serviceObjective" [checked]="serviceObjective === objectives.DEPARTURE" [ngClass]="{'accent-orange': serviceObjective === objectives.DEPARTURE}" [value]="serviceObjective === objectives.DEPARTURE" />
      <div class="fnt-14 f500 mar-left-5 objective-label" [ngClass]="{'active': serviceObjective === objectives.DEPARTURE}">Drop to Airport</div>
    </div>
    <div class="d-flex mar-left-80" [ngClass]="{'mar-left-80': !deviceService.isMobile}" (click)="selectArrival()">
      <input type="radio" name="serviceObjective" [checked]="serviceObjective === objectives.ARRIVAL" [ngClass]="{'accent-orange': serviceObjective === objectives.ARRIVAL}" [value]="serviceObjective === objectives.ARRIVAL" />
      <div class="fnt-14 f500 mar-left-5 objective-label" [ngClass]="{'active': serviceObjective === objectives.ARRIVAL}">Pickup from Airport</div>
    </div>
  </div>

  <div class="mar-top-xs-16">
    <form [formGroup]="searchForm" (submit)="searchAvailability()" *ngIf="!isReadOnly">
      <div class="row d-block mar-top-15 mar-left-0 mar-right-0" [ngClass]="{'d-block': !deviceService.isMobile, 'd-flex': deviceService.isMobile}">
        <div class="d-flex flex-column col-md-6 col-12 pad-right-0 pad-left-0" style="float: right;">
          <div class="form-label fnt-14 f500">
            Airport
          </div>
          <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5 relative" dropdown #dropdown="bs-dropdown" [autoClose]="true" [insideClick]='true'>
            <input type="text" formControlName="airport" dropdownToggle [(ngModel)]="airportSearchQuery" (input)="dropdown.show()" [value]="selectedAirport?.airport_name" class="form-control h100 w100 pad-left-18 border-0 cfff bg-transparent dropdown-toggle" placeholder="Enter Flight {{serviceObjective === objectives?.DEPARTURE ? 'Origin' : 'Destination'}}"   />
            <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu w-100 pt-1" role="menu" aria-labelledby="button-triggers-manual absolute">
              <li role="menuitem" *ngFor="let airport of airports | filter: airportSearchQuery: 'airport_name'" (click)="selectAirport(airport);dropdown.hide();handleFormInputChange()">
                <a class="dropdown-item w-100">{{airport.airport_name}}, {{airport.city}}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0 mar-top-xs-16">
          <div class="form-label fnt-14 f500">
            {{serviceObjective === objectives?.DEPARTURE ? 'Pickup' : 'Drop-off'}} Address
          </div>
          <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
            <input type="text" formControlName="address" class="form-control h100 pad-left-18 w100 border-0 cfff bg-transparent" (input)="handleFormInputChange()" placeholder="Enter {{serviceObjective === objectives?.DEPARTURE ? 'Pickup' : 'Drop-off'}} location (Area, street or landmark)" />
          </div>
          <div *ngIf="isSubmitted && searchForm.controls.address?.invalid" class="warning warning-text">
            <div *ngIf="searchForm.controls?.address?.required">
                Address is required.
            </div>
          </div>
        </div>
      </div>
      <div class="row mar-top-15 mar-left-0 mar-right-0 d-flex">
        <div class="d-flex flex-column col-md-6 col-12 pad-xs-right-0 pad-left-0">
          <div class="form-label fnt-14 f500">
            Pickup Date
          </div>
          <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
            <input type="text"
              class="form-control h100 w100 border-0 pad-left-18 cfff bg-transparent"
              placeholder="Select Pickup Date"
              [bsConfig]="{isAnimated: true,dateInputFormat: 'DD/MM/YYYY',showWeekNumbers: false, containerClass: 'theme-black'}" 
              bsDatepicker formControlName="date"
              [(ngModel)]="pickupdDate"
              [minDate]="minDate"
              readonly
              (ngModelChange)="handleFormInputChange()"
            >
          </div>
        </div>
        <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-right-0 mar-top-xs-16">
          <div class="form-label fnt-14 f500">
            Pickup Time
          </div>
          <div class="flex mar-top-5">
            <div class="input-box w100 focus-active-border dark-input rounded border border-transparent">
                <input class="form-control cfff bg-transparent f500 pad-left-18 fnt-14 border-0" placeholder="Hours" (input)="handleFormInputChange();" formControlName="hour" maxlength="2">
            </div>
            <div class="flex align-center pad-left-5 pad-right-5 cfff">:</div>
            <div class="input-box w100 focus-active-border dark-input rounded border border-transparent">
                <input class="form-control cfff bg-transparent f500 pad-left-18 fnt-14 border-0" placeholder="Minutes" (input)="handleFormInputChange();isPickUpDateValid()" formControlName="mins" maxlength="2">
            </div>
            <div class="pointer relative am-pm-drop mar-left-10" dropdown>
                <div dropdownToggle class="drop-txt h100 input-box w100 focus-active-border dark-input rounded border border-transparent">
                    <input class="form-control cfff bg-transparent text-uppercase pad-left-18 f500 fnt-14 border-0" readonly formControlName="time_type">
                </div>
                <div class="dropdown-menu default-dropdown-menu w100 pad-5" *dropdownMenu>
                    <div class="drop-item fnt-13 pad-5 pad-left-10 pad-right-10 text-uppercase cfff"
                        *ngFor="let item of timeType" (click)="searchForm.get('time_type').patchValue(item);handleFormInputChange();isPickUpDateValid()">
                        {{item}}</div>
                </div>
            </div>
          </div>
          <div *ngIf="isSubmitted && (searchForm.controls.hour.invalid || !isTimeValid)" class="warning warning-text">
            <div *ngIf="!isTimeValid">
              Service  request has to be made at least 48 hours in advance.
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row pad-left-0 mar-top-15">
        <svg width="19" height="19" viewBox="0 0 23 25" fill="#EC9B26" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3469 16.7165C10.7946 16.7166 10.3468 16.269 10.3467 15.7167L10.3461 11.7167C10.346 11.1644 10.7936 10.7166 11.3459 10.7165C11.8982 10.7165 12.346 11.1641 12.3461 11.7164L12.3467 15.7164C12.3468 16.2687 11.8991 16.7165 11.3469 16.7165Z"/>
            <path d="M11.3453 6.71654C10.793 6.71663 10.3454 7.16441 10.3455 7.7167C10.3455 8.26898 10.7933 8.71663 11.3456 8.71654C11.8979 8.71646 12.3455 8.26867 12.3455 7.71639C12.3454 7.16411 11.8976 6.71646 11.3453 6.71654Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3461 11.7148C22.347 17.79 17.4229 22.7156 11.3478 22.7165C5.27265 22.7175 0.347019 17.7934 0.34608 11.7182C0.345142 5.64311 5.26925 0.717483 11.3444 0.716544C17.4195 0.715606 22.3451 5.63971 22.3461 11.7148ZM11.3475 20.7165C16.318 20.7158 20.3468 16.6857 20.3461 11.7152C20.3453 6.74459 16.3153 2.71578 11.3447 2.71654C6.37413 2.71731 2.34531 6.74737 2.34608 11.7179C2.34685 16.6885 6.37691 20.7173 11.3475 20.7165Z"/>
        </svg>                    
        <div class="note fnt-12 f400 mar-left-5">
          Booking request can only be made 48 hours in advance from service time (local time zone).
        </div>
      </div>
      <button class="btn pointer custom-button fnt-16 f700 pad-top-14 pad-bot-14 pad-left-18 pad-right-18 border-0 mar-top-32 secondary-founder" *ngIf="!isValid" type="submit">
        Search Availability
      </button>
      <div class="d-flex flex-wrap mar-top-32" *ngIf="isValid">
        <button class="btn pointer custom-button fnt-16 f700 pad-top-14 pad-bot-14 pad-left-18 pad-right-18 border-0 primary d-flex mar-right-10" type="submit">
          Search Availability 
          <div class="small-loader merged-card-loader mar-left-10 mt-auto mb-auto" *ngIf="searchingAvailableServices"></div>
        </button>
        <div class="d-flex flex-row align-items-center" *ngIf="isServiceNotAvailable">
          <img src="../../../../../../assets/icons/warning.svg" class="warning-icon mar-right-5" />
          <div class="warning warning-text">
            "Sorry! This service is not available for the selected airport."
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="isReadOnly">
      <div class="row d-block mar-left-0 mar-right-0" [ngClass]="{'d-block': !deviceService.isMobile, 'd-flex': deviceService.isMobile}">
        <div class="d-flex flex-column col-md-6 col-12 pad-right-0 pad-left-0" style="float: right;">
          <div class="form-label fnt-14 f500">
            Airport
          </div>
          <div class="input-box w100 dark-input rounded border border-transparent mar-top-5 relative">
            <div class="form-control h100 w100 border-0 pad-left-18 bg-transparent c8F93A4 d-flex align-items-center">
              {{searchAvailabilityFormValues?.airport}}, {{selectedAirport?.city}}
            </div>
          </div>
        </div>
        <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0 mar-top-xs-16">
          <div class="form-label fnt-14 f500">
            {{serviceObjective === objectives?.DEPARTURE ? 'Pickup' : 'Drop-off'}} Address
          </div>
          <div class="input-box w100 dark-input rounded border border-transparent mar-top-5">
            <div class="form-control h100 w100 border-0 pad-left-18 bg-transparent c8F93A4 d-flex align-items-center text-capitalize">
              {{searchAvailabilityFormValues?.address}}
            </div>
          </div>
        </div>
      </div>
      <div class="row mar-top-15 mar-left-0 mar-right-0 d-flex">
        <div class="d-flex flex-column col-md-6 col-12 pad-xs-right-0 pad-left-0">
          <div class="form-label fnt-14 f500">
            Pickup Date
          </div>
          <div class="input-box w100 dark-input rounded border border-transparent mar-top-5">
            <div class="form-control h100 w100 border-0 pad-left-18 bg-transparent c8F93A4 d-flex align-items-center">
              {{formatDate(searchAvailabilityFormValues?.date)}}
            </div>
          </div>
        </div>
        <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-right-0 mar-top-xs-16">
          <div class="form-label fnt-14 f500">
            Pickup Time
          </div>
          <div class="input-box w100 dark-input rounded border border-transparent mar-top-5">
            <div class="form-control bg-transparent f500 fnt-14 pad-left-18 border-0 c8F93A4 d-flex align-items-center text-uppercase">
              {{formatTime(searchAvailabilityFormValues?.hour+":"+searchAvailabilityFormValues?.mins+" "+searchAvailabilityFormValues?.time_type)}}
            </div>
          </div>
        </div>
      </div>
      <div class="row mar-top-15 mar-left-0 mar-right-0 d-flex" *ngIf="terminals.length > 0">
        <div class="d-flex flex-column col-md-6 col-12 pad-xs-right-0 pad-left-0">
          <div class="form-label fnt-14 f500">
            Let us know the {{serviceObjective === objectives.DEPARTURE ? 'Drop-off' : 'Pickup'}} Terminal
          </div>
          <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5 relative" dropdown #dropdown="bs-dropdown" [autoClose]="true" [insideClick]='true' [isDisabled]="isReadOnly && activeStep > 2 ">
            <input type="text" dropdownToggle id="terminal" [value]="selectedTerminal?.terminal_name" readonly class="form-control h100 w100 pad-left-18 border-0 cfff bg-transparent dropdown-toggle fnt-14 f500" placeholder="Select Terminal"   />
            <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="button-triggers-manual absolute">
              <li role="menuitem" *ngFor="let terminal of terminals" (click)="selectTerminal(terminal);dropdown.hide();">
                <a class="dropdown-item w-100">{{terminal?.terminal_name}}</a>
              </li>
            </ul>
          </div>
          <div *ngIf="showRequiredTerminalMsg" class="warning warning-text">
            Terminal is required
          </div>
        </div>
      </div>
    </div>
  </div>

</div>