import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/constants/global-variables';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit {

  currentUrl: string = "";
  @Input() hideNavMenu: boolean;

  constructor(public router: Router, private route: ActivatedRoute, public globalConstants: GlobalConstants) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    })
  }

  ngOnInit(): void {
  }

}
