import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-back-confirmation-modal',
  templateUrl: './back-confirmation-modal.component.html',
  styleUrls: ['./back-confirmation-modal.component.scss']
})
export class BackConfirmationModalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<BackConfirmationModalComponent>
  ) { }

  ngOnInit(): void {
  }

  handleClose() {
    this.dialogRef.close();
  }

  handleConfirm() {
    this.dialogRef.close(true);
  }

}
