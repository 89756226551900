import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { DeviceService } from 'src/app/common/services/device.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { RedemptionService } from 'src/app/shared/services/redemption.service';

@Component({
  selector: 'app-coupon-modal',
  templateUrl: './coupon-modal.component.html',
  styleUrls: ['./coupon-modal.component.scss']
})
export class CouponModalComponent implements OnInit {


  couponDetails: any;
  copiedCode: boolean = false;
  couponLoading: boolean;
  showCopiedState: boolean = false;
  redeemActive: boolean = false;
  redeeming = false;
  voucherData:any = {};
  hideRedeemButton: boolean = false;
  isMembership: boolean;
  userDetails: any;
  membership: any;
  merchant: any;
  offer: any;

  emitRedeem = new EventEmitter<boolean>();
  termsOpen: EventEmitter<any> = new EventEmitter();
  hideEvent: EventEmitter<boolean> = new EventEmitter();

  isPriorityCheckIn: boolean = false;
  isAirportLoungeFlow: boolean = false;
  isTheKen: boolean = false;
  
  constructor(public GlobalConstants: GlobalConstants,
    private _apiService: ApiService,
    private toastr: ToastrService,
    public _bsModalRef: BsModalRef,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private redemptionService: RedemptionService,
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    @Inject(DOCUMENT) private document: any,
  ) {
    if(document.location.pathname.includes("membership")) {
      this.isMembership = true;
    } else {
      this.isMembership = false;
    }
    if(this.deviceService.isMobile) {
      let menu_bar: any = document.getElementsByClassName("navigation-items-container")[0];
      menu_bar.style.zIndex = '0';
    }
  }

  ngOnDestroy() {
    if(this.deviceService.isMobile) {
      let menu_bar: any = document.getElementsByClassName("navigation-items-container")[0];
      menu_bar.style.zIndex = '';
    }
  }

  populateCouponData() {
    if(this.isMembership) {
      this.couponDetails = this._apiService.processSelectedMembership(this.membership, this.merchant);
    } else {
      this.couponDetails = this._apiService.processSelectedOffer(this.offer, this.merchant)
      this.couponDetails["type"] = this.couponDetails.offerType;
    }
  }

  ngOnInit(): void {
    if(this.merchant?.id) {
      let environment_type = this.document.location.hostname.includes("sandbox") ? 'sandbox' : 'prod';
      this.isAirportLoungeFlow = this.GlobalConstants.airportLoungeIds[environment_type].includes(this.merchant?.id);
      this.isPriorityCheckIn = this.GlobalConstants.priorityChecInIds[environment_type].includes(this.merchant?.id);
      this.isTheKen = this.GlobalConstants.theKen[environment_type].includes(this.merchant?.id);
      console.log(this.isTheKen, "isKen");
    }
    this.populateCouponData();
    this.proceedToRedeem();
  }

  proceedToRedeem() {
    this.couponLoading = true;
    this.changeDetector.detectChanges();
    let params = {}
    this._apiService.redeem({ id: this.isMembership ? this.membership.id : this.offer.id }, params, this.isMembership).subscribe(
      (res: any) => {
        this.voucherData = {
          success: res.success,
          voucher_code: res?.voucher?.code || res?.voucher_code || ""
        };
        this.couponLoading = false;
        this.changeDetector.detectChanges();
        this.redemptionService.getPerksData();
      },
      (err) => {
        if (err. status && err.status === 401) {
          this._bsModalRef.hide()
        } else {
          this.couponLoading = false;
          this.voucherData.success = false;
        }
        this.changeDetector.detectChanges();
      }
    );
  }

  goTo() {
    let gotourl = this.couponDetails.redemptionUrl;
    window.open(gotourl, '_blank');
  }

  redirectToTerms() {
    if(this._bsModalRef) {
      this._bsModalRef.hide();
    }
    this.router.navigate([ (this.isMembership ? '/memberships/membership-details/' : '/rewards/offer-details/')+this.merchant.id, {showTerms: true}]);
  }

  copy(code) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Copied!');
    this.showCopiedState = true;
    setTimeout(() => {
      this.changeDetector.detectChanges();
      this.showCopiedState = false;
    }, 2000);
  }

  closeModal() {
    this._bsModalRef.hide();
  }


}
