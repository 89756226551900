<div class="grey2-container">
    <app-search-availability [isReadOnly]="true"></app-search-availability>

    <div class="custom-border border-05"></div>
    <div class="d-flex flex-column pad-top-24 pad-bot-24">
        <div class="d-flex flex-column" [ngClass]="{'pad-left-32 pad-right-32': !deviceService.isMobile, 'pad-left-16 pad-right-16': deviceService.isMobile}">
            <div class="d-flex flex-row">
                <img src="../../../../../../assets/icons/flight.svg" class="flight-icon" />
                <div class="fnt-18 f700 mar-left-5 cfff">
                    Flight Details
                </div>
            </div>
            <div class="cfff-05-opacity fnt-12 f400 mar-top-8">
                Let us know your flight itinerary.
            </div>
        </div>

        <form [formGroup]="serviceForm" (submit)="handleSubmit()">
            <div [ngClass]="{'pad-left-32 pad-right-32': !deviceService.isMobile, 'pad-left-16 pad-right-16': deviceService.isMobile}" class="pad-bot-24">
                <div class="row d-flex mar-top-15 mar-left-0 mar-right-0">
                    <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0">
                        <div class="form-label fnt-14 f500 cfff">
                            Flight Origin
                        </div>
                        <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5 relative" [isDisabled]="selectedJourney == airportService.objectives.DEPARTURE" dropdown #dropdown="bs-dropdown" [autoClose]="true" [insideClick]='true'>
                            <input type="text" formControlName="flight_origin" [(ngModel)]="flightOriginSearchQuery" dropdownToggle [attr.readonly]="selectedJourney == airportService.objectives.DEPARTURE ? 'true' : null" [value]="selectedOriginAirport?.airport_name" class="form-control h100 w100 pad-left-18 border-0 cfff bg-transparent dropdown-toggle" placeholder="Enter Flight Origin"  (input)="handleAirportGlobalSearch($event)" />
                            <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="button-triggers-manual absolute">
                              <li role="menuitem" *ngFor="let airport of searchedAirports | filter: flightOriginSearchQuery : 'airport_name'" (click)="selectOriginAirport(airport);dropdown.hide();">
                                <a class="dropdown-item w-100">{{airport.airport_name}}, {{airport.city}}</a>
                              </li>
                            </ul>
                        </div>
                        <div *ngIf="formSubmited && serviceForm.controls.flight_origin.invalid" class="warning warning-text">
                            <div *ngIf="serviceForm.controls.flight_origin.errors?.['required']">
                                Flight Origin is required.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0 mar-top-xs-16">
                        <div class="form-label fnt-14 f500 cfff">
                            Flight Destination
                        </div>
                        <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5 relative" [isDisabled]="selectedJourney == airportService.objectives.ARRIVAL" dropdown #dropdown="bs-dropdown" [autoClose]="true" [insideClick]='true'>
                            <input type="text" formControlName="flight_destination" [(ngModel)]="flightDestinationSearchQuery" [attr.readonly]="selectedJourney == airportService.objectives.ARRIVAL ? 'true' : null" dropdownToggle [value]="selectedDestinationAirport?.airport_name" class="form-control h100 w100 pad-left-18 border-0 cfff bg-transparent dropdown-toggle" placeholder="Enter Flight Destination" (input)="handleAirportGlobalSearch($event)" />
                            <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="button-triggers-manual absolute">
                              <li role="menuitem" *ngFor="let airport of searchedAirports | filter: flightDestinationSearchQuery : 'airport_name'" (click)="selectDestinationAirport(airport);dropdown.hide();">
                                <a class="dropdown-item w-100">{{airport.airport_name}}, {{airport.city}}</a>
                              </li>
                            </ul>
                        </div>
                        <div *ngIf="formSubmited && serviceForm.controls.flight_destination.invalid" class="warning warning-text">
                            <div *ngIf="serviceForm.controls.flight_destination.errors?.['required']">
                                Flight Destination is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-flex mar-top-15 mar-left-0 mar-right-0">
                    <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0">
                        <div class="form-label fnt-14 f500 cfff">
                            {{(selectedJourney == airportService.objectives.DEPARTURE ? 'Departure' : 'Arrival' ) +' Flight Number'}}
                        </div>
                        <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
                            <input type="text" formControlName="flight_number" class="form-control h100 w100 pad-left-18 border-0 cfff bg-transparent" placeholder="Enter Flight Number"/>
                        </div>
                        <div *ngIf="formSubmited && serviceForm.controls.flight_number.invalid" class="warning warning-text">
                            <div *ngIf="serviceForm.controls.flight_number.errors?.['required']">
                                Flight Number is required.
                            </div>
                            <div *ngIf="serviceForm.controls.flight_number.errors?.['maxlength']">
                                Flight Number must be max 8 characters long.
                            </div>
                            <div *ngIf="serviceForm.controls.flight_number.errors?.['minlength']">
                                Flight Number must be at least 5 characters long.
                            </div>
                            <div *ngIf="serviceForm.controls.flight_number.errors?.pattern">
                                Please enter a valid Flight Number
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0 mar-top-xs-16" *ngIf="selectedJourney == airportService.objectives.DEPARTURE">
                        <div class="form-label fnt-14 f500 cfff">
                            Flight {{selectedJourney == airportService.objectives.DEPARTURE ? 'Departure' : 'Arrival'}} Date
                        </div>
                        <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
                            <input type="text"
                                *ngIf="selectedJourney == airportService.objectives.DEPARTURE"
                                class="form-control h100 w100 border-0 pad-left-18 cfff bg-transparent"
                                placeholder="{{'Enter Flight '+(selectedJourney == airportService.objectives.DEPARTURE ? 'Departure' : 'Arrival')+' Date'}}"
                                [bsConfig]="{isAnimated: true,dateInputFormat: 'DD/MM/YYYY',showWeekNumbers: false, containerClass: 'theme-black'}" 
                                bsDatepicker 
                                readonly
                                formControlName="journey_date"
                                [(ngModel)]="journeyDate"
                                [minDate]="minJourneyDate"
                            >
                            <input type="text"
                                *ngIf="selectedJourney == airportService.objectives.ARRIVAL"
                                class="form-control h100 w100 border-0 pad-left-18 cfff bg-transparent"
                                placeholder="{{'Enter Flight '+(selectedJourney == airportService.objectives.DEPARTURE ? 'Departure' : 'Arrival')+' Date'}}"
                                [bsConfig]="{isAnimated: true,dateInputFormat: 'DD/MM/YYYY',showWeekNumbers: false, containerClass: 'theme-black'}" 
                                bsDatepicker 
                                readonly
                                formControlName="journey_date"
                                [(ngModel)]="journeyDate"
                                [maxDate]="maxJourneyDate"
                            >
                        </div>
                        <div *ngIf="formSubmited && serviceForm.controls.journey_date.invalid" class="warning warning-text">
                            <div *ngIf="serviceForm.controls.journey_date.errors?.['required']">
                                Flight {{selectedJourney == airportService.objectives.DEPARTURE ? 'Departure' : 'Arrival'}} Date is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-right-0 mar-top-xs-16 mar-top-15" *ngIf="selectedJourney == airportService.objectives.DEPARTURE">
                    <div class="form-label fnt-14 f500">
                        {{selectedJourney == airportService.objectives.DEPARTURE ? 'Departure' : 'Arrival'}} Time
                    </div>
                    <div class="flex mar-top-5">
                      <div class="input-box w100 focus-active-border dark-input rounded border border-transparent">
                          <input class="form-control cfff bg-transparent f500 pad-left-18 fnt-14 border-0" placeholder="Hours" type="number" formControlName="journey_hour" maxlength="2">
                      </div>
                      <div class="flex align-center pad-left-5 pad-right-5 cfff">:</div>
                      <div class="input-box w100 focus-active-border dark-input rounded border border-transparent">
                          <input class="form-control cfff bg-transparent f500 pad-left-18 fnt-14 border-0" placeholder="Minutes" type="number" formControlName="journey_mins" maxlength="2">
                      </div>
                      <div class="pointer relative am-pm-drop mar-left-10" dropdown>
                          <div dropdownToggle class="drop-txt h100 input-box w100 focus-active-border dark-input rounded border border-transparent">
                              <input class="form-control cfff bg-transparent text-uppercase pad-left-18 f500 fnt-14 border-0" readonly formControlName="journey_time_type">
                          </div>
                          <div class="dropdown-menu default-dropdown-menu w100 pad-5" *dropdownMenu>
                              <div class="drop-item fnt-14 pad-5 pad-left-10 pad-right-10 text-uppercase cfff"
                                  *ngFor="let item of timeType" (click)="serviceForm.get('journey_time_type').patchValue(item);">
                                  {{item}}</div>
                          </div>
                      </div>
                    </div>
                    <div *ngIf="formSubmited && (serviceForm.controls.journey_hour.invalid || serviceForm.controls.journey_mins.invalid || serviceForm.controls.journey_time_type.invalid)" class="warning warning-text">
                        <div *ngIf="serviceForm.controls.journey_hour.errors?.['required'] || serviceForm.controls.journey_mins.errors?.['required'] || serviceForm.controls.journey_time_type.errors?.['required']">
                            Flight {{selectedJourney == airportService.objectives.DEPARTURE ? 'Departure' : 'Arrival'}} Time is required.
                        </div>
                        <div *ngIf="serviceForm.controls.journey_hour?.errors?.min || serviceForm.controls.journey_mins?.errors?.min">
                            Service  request has to be made at least 48 hours in advance.
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row pad-left-0 mar-top-15" *ngIf="selectedJourney == airportService.objectives.DEPARTURE">
                    <svg width="19" height="19" viewBox="0 0 23 25" fill="#EC9B26" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.3469 16.7165C10.7946 16.7166 10.3468 16.269 10.3467 15.7167L10.3461 11.7167C10.346 11.1644 10.7936 10.7166 11.3459 10.7165C11.8982 10.7165 12.346 11.1641 12.3461 11.7164L12.3467 15.7164C12.3468 16.2687 11.8991 16.7165 11.3469 16.7165Z"/>
                        <path d="M11.3453 6.71654C10.793 6.71663 10.3454 7.16441 10.3455 7.7167C10.3455 8.26898 10.7933 8.71663 11.3456 8.71654C11.8979 8.71646 12.3455 8.26867 12.3455 7.71639C12.3454 7.16411 11.8976 6.71646 11.3453 6.71654Z"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3461 11.7148C22.347 17.79 17.4229 22.7156 11.3478 22.7165C5.27265 22.7175 0.347019 17.7934 0.34608 11.7182C0.345142 5.64311 5.26925 0.717483 11.3444 0.716544C17.4195 0.715606 22.3451 5.63971 22.3461 11.7148ZM11.3475 20.7165C16.318 20.7158 20.3468 16.6857 20.3461 11.7152C20.3453 6.74459 16.3153 2.71578 11.3447 2.71654C6.37413 2.71731 2.34531 6.74737 2.34608 11.7179C2.34685 16.6885 6.37691 20.7173 11.3475 20.7165Z"/>
                    </svg>                    
                    <div class="note fnt-12 f400 mar-left-5">
                        The Departure time needs to be entered as per local time zone.
                    </div>
                </div>
            </div>
            <div class="custom-border border-05"></div>
            <div class="d-flex flex-column pad-top-24 pad-bot-24" [ngClass]="{'pad-left-32 pad-right-32': !deviceService.isMobile, 'pad-left-16 pad-right-16': deviceService.isMobile}">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row">
                        <img src="../../../../../../assets/icons/traveller.svg" class="flight-icon" />
                        <div class="fnt-18 f700 mar-left-5 cfff">
                            Traveller Details
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column mar-top-15 mar-left-0 mar-right-0">
                    <div class="row mar-top-10 mar-left-0 mar-right-0 p-0">
                        <div class="col-4 d-flex flex-column pad-left-0 pad-right-7">
                            <label class="fnt-12 f500 form-label">Adults</label>
                        <app-plus-minus (changeEvent)="update($event,'adults', 'person')" [num]="serviceForm.controls['adults'].value"></app-plus-minus>
                            <div *ngIf="formSubmited && serviceForm.controls.adults.invalid">
                                <div class="pad-top-5 warning warning-text text-nowrap">
                                    Minimum 1 Adult and Maximum 9 persons allowed
                                </div>
                            </div>
                        </div>
                        <div class="col-4 d-flex flex-column pad-left-7 pad-right-7">
                            <label class="fnt-12 f500 form-label">Children</label>
                            <app-plus-minus (changeEvent)="update($event,'children', 'person')" [num]="serviceForm.controls['children'].value"></app-plus-minus>
                        </div>
                        <div class="col-4 d-flex flex-column pad-left-7 pad-right-0">
                            <label class="fnt-12 f500 form-label">Infants</label>
                            <app-plus-minus (changeEvent)="update($event,'infants', 'person')" [num]="serviceForm.controls['infants'].value" style="width: 'fit-content';">
                            </app-plus-minus>
                        </div>
                    </div>
                    <div class="d-flex flex-row pad-left-0 mar-top-15">
                        <svg width="19" height="19" viewBox="0 0 23 25" fill="#EC9B26" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3469 16.7165C10.7946 16.7166 10.3468 16.269 10.3467 15.7167L10.3461 11.7167C10.346 11.1644 10.7936 10.7166 11.3459 10.7165C11.8982 10.7165 12.346 11.1641 12.3461 11.7164L12.3467 15.7164C12.3468 16.2687 11.8991 16.7165 11.3469 16.7165Z"/>
                            <path d="M11.3453 6.71654C10.793 6.71663 10.3454 7.16441 10.3455 7.7167C10.3455 8.26898 10.7933 8.71663 11.3456 8.71654C11.8979 8.71646 12.3455 8.26867 12.3455 7.71639C12.3454 7.16411 11.8976 6.71646 11.3453 6.71654Z"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3461 11.7148C22.347 17.79 17.4229 22.7156 11.3478 22.7165C5.27265 22.7175 0.347019 17.7934 0.34608 11.7182C0.345142 5.64311 5.26925 0.717483 11.3444 0.716544C17.4195 0.715606 22.3451 5.63971 22.3461 11.7148ZM11.3475 20.7165C16.318 20.7158 20.3468 16.6857 20.3461 11.7152C20.3453 6.74459 16.3153 2.71578 11.3447 2.71654C6.37413 2.71731 2.34531 6.74737 2.34608 11.7179C2.34685 16.6885 6.37691 20.7173 11.3475 20.7165Z"/>
                        </svg>                    
                        <div class="note fnt-12 f400 mar-left-5">
                            We need any one travellers details as we would like to send you booking details.
                        </div>
                    </div>
                </div>
                
                <div class="row d-flex fex mar-top-15 mar-left-0 mar-right-0">
                    <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0">
                        <div class="form-label fnt-14 f500 cfff">
                            Traveller Name
                        </div>
                        <div class="d-flex flex-row">
                            <div class="relative" dropdown #dropdown="bs-dropdown">
                                <div dropdownToggle class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
                                    <div class="form-control flex align-center f500 fnt-14 bg-transparent border-0" [ngClass]="{'c8F93A4': !serviceForm.controls['traveller_title'].value, 'cfff': serviceForm.controls['traveller_title'].value}">
                                        {{serviceForm.controls['traveller_title'].value || 'Title'}}
                                    </div>
                                    <!-- <svg class="absolute drop-icon" width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L5.5 5L10 1" stroke="#64707C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg> -->
                                </div>
                                <div class="dropdown-menu default-dropdown-menu w100 pad-5 cfff" *dropdownMenu>
                                    <div class="drop-item fnt-14 pad-5 pad-left-10 pad-right-10 pointer" 
                                    *ngFor="let title of personType" (click) = "serviceForm.get('traveller_title').patchValue(title)">{{title}}</div>
                                </div>
                                <div *ngIf="formSubmited && serviceForm.controls.traveller_title.invalid" class="warning warning-text">
                                    <div *ngIf="serviceForm.controls.traveller_title.errors?.['required']">
                                        Title is required
                                    </div>
                                </div>
                            </div>
                            <div class="mar-left-5">
                                <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
                                    <input type="text" formControlName="traveller_first_name" class="form-control h100 w100 pad-left-18 border-0 cfff bg-transparent" placeholder="Enter First Name"/>
                                </div>
                                <div *ngIf="formSubmited && serviceForm.controls.traveller_first_name.invalid" class="warning warning-text">
                                    <div *ngIf="serviceForm.controls.traveller_first_name.errors?.['required']">
                                        First Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="mar-left-5">
                                <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
                                    <input type="text" formControlName="traveller_last_name" class="form-control h100 w100 pad-left-18 border-0 cfff bg-transparent" placeholder="Last Name"/>
                                </div>
                                <div *ngIf="formSubmited && serviceForm.controls.traveller_last_name.invalid" class="warning warning-text">
                                    <div *ngIf="serviceForm.controls.traveller_last_name.errors?.['required']">
                                        Last Name is required
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0 mar-top-xs-16">
                        <div class="form-label fnt-14 f500 cfff">
                            Traveller Email Address
                        </div>
                        <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
                            <input type="email" formControlName="traveller_email" class="form-control h100 pad-left-18 w100 border-0 cfff bg-transparent" placeholder="Enter Email Address" />
                        </div>
                        <div *ngIf="formSubmited && serviceForm.controls.traveller_email.invalid" class="warning warning-text">
                            <div *ngIf="serviceForm.controls.traveller_email.errors?.['required']">
                                Traveller Email is required.
                            </div>
                            <div *ngIf="serviceForm.controls.traveller_email.errors?.pattern">
                                Please enter a valid email
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-flex fex mar-top-15 mar-left-0 mar-right-0">
                    <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0">
                        <div class="form-label fnt-14 f500 cfff">
                            Traveller Phone Number
                        </div>
                        <div class="d-flex">
                            <div class="country-block d-flex align-center justify-center searchdrop relative custom-border right input-box mar-top-5" (onHidden)="countrySearchTerm = ''; filterItem(countrySearchTerm)" dropdown #dropdown="bs-dropdown" [autoClose]="true" [insideClick]="true">
                                <div class="w100 h-100 d-flex align-center pointer relative btn border-0" dropdownToggle>
                                    <img [src]="selectedCountry?.attributes?.flag_url" alt="Flag" class="relative country-flag" *ngIf="selectedCountry" />
                                    <div class="mar-left-5">
                                        <svg class="left" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 4L0 0L8 6.28116e-07L4 4Z" fill="#98A2B4" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="dropdown-menu dropdown-menu-shadow w100 country-list mar-top-6 p-2" *dropdownMenu>
                                    <div class="select-input-parent pad-8 w100 z1 pb-0 pt-0">
                                        <input autocomplete="new-password" class="select-input form-control fnt-13 bg-transparent border-0 cfff p-0" [(ngModel)] = "countrySearchTerm" [ngModelOptions]="{standalone: true}" (input)="filterItem(countrySearchTerm)" placeholder="Search country">
                                    </div>
                                    <div class="block loc-results">
                                        <div *ngFor="let country of filteredCountries; let i = index" (click)="countrySearchTerm = '';setCountry(country);dropdown.hide()" dropdownToggle [ngClass]="{'selected-country': country.attributes?.iso_code == selectedCountry.iso_code}">
                                        <div class="flex relative country-item pointer pad-10">
                                            <img [src]="country.attributes.flag_url" class="country-flag mt-auto mb-auto">
                                            <div class="item-name flex align-center cfff fnt-14 w100 mar-left-5">
                                                {{country.attributes.name}}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
                                <input 
                                    formControlName="traveller_mobile" 
                                    [(ngModel)]="mobNum" 
                                    type="text" 
                                    class="form-control h100 w100 pad-left-18 border-0 cfff bg-transparent" 
                                    placeholder="Enter Phone Number" 
                                    (input)="removeLetter($event.target.value);"
                                />
                            </div>
                        </div>
                        <div *ngIf="formSubmited && serviceForm.controls.traveller_mobile.invalid" class="warning warning-text">
                            <div *ngIf="serviceForm.controls.traveller_mobile.errors?.['required']">
                                Phone Number is required.
                            </div>
                            <div *ngIf="serviceForm.controls.traveller_mobile.errors?.minLength || serviceForm.controls.traveller_mobile.errors?.maxLength">
                                Please enter a valid number
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0 mar-top-xs-16">
                        <div class="form-label fnt-14 f500 cfff">
                            Date of Birth
                        </div>
                        <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
                            <input type="text"
                                class="form-control h100 w100 border-0 pad-left-18 cfff bg-transparent"
                                placeholder="Enter Date of Birth"
                                [bsConfig]="{isAnimated: true,dateInputFormat: 'DD/MM/YYYY',showWeekNumbers: false, containerClass: 'theme-black'}" 
                                bsDatepicker 
                                readonly
                                formControlName="traveller_date_of_birth"
                                [(ngModel)]="dateOfBirth"
                            >
                        </div>
                        <div *ngIf="formSubmited && serviceForm.controls.traveller_date_of_birth.invalid" class="warning warning-text">
                            <div *ngIf="serviceForm.controls.traveller_date_of_birth.errors?.['required']">
                                Date of Birth is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-flex fex mar-top-15 mar-left-0 mar-right-0">
                    <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0">
                        <div class="form-label fnt-14 f500 cfff">
                            PNR Number
                        </div>
                        <div class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
                            <input 
                                formControlName="pnr_number" 
                                type="text" 
                                class="form-control h100 w100 pad-left-18 border-0 cfff bg-transparent" 
                                placeholder="Enter PNR Number" 
                            />
                        </div>
                        <div *ngIf="formSubmited && serviceForm.controls.pnr_number.invalid" class="warning warning-text">
                            <div *ngIf="serviceForm.controls.pnr_number.errors?.['required']">
                                PNR Number is required.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column col-md-6 col-12 pad-left-0 pad-xs-right-0 mar-top-xs-16">
                        <div class="form-label fnt-14 f500 cfff">
                            Travel Class
                        </div>
                        <div class="pointer relative cfff" dropdown>
                            <div dropdownToggle class="input-box w100 focus-active-border dark-input rounded border border-transparent mar-top-5">
                                <div class="form-control h100 w100 pad-left-18 border-0 cfff bg-transparent d-flex align-center" [ngClass]="{'c8F93A4': !serviceForm.controls['travel_class'].value, 'cfff': serviceForm.controls['travel_class'].value}">{{serviceForm.controls['travel_class'].value || 'Select travel type'}}</div>
                            </div>
                            <div class="dropdown-menu default-dropdown-menu w100 pad-5" *dropdownMenu>
                                <div class="drop-item fnt-14 pad-5 pad-left-10 pad-right-10 cfff" *ngFor="let item of flightClasses"
                                    (click)="serviceForm.get('travel_class').patchValue(item)">{{item}}</div>
                            </div>
                        </div>
                        <div *ngIf="formSubmited && serviceForm.get('travel_class').invalid">
                            <div *ngIf="serviceForm.get('travel_class').errors?.['required']" class="warning warning-text">
                                Flight Class is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column mar-bot-35 mar-top-15" [ngClass]="{'pad-left-32 pad-right-32': !deviceService.isMobile, 'pad-left-16 pad-right-16': deviceService.isMobile}">
                <div class="d-flex flex-row">
                    <img src="../../../../../../assets/icons/baggage.svg" class="baggage-icon" />
                    <div class="fnt-18 f700 mar-left-5 cfff">
                        Add your Baggage
                    </div>
                </div>
                <div class="d-flex flex-column mar-top-15 mar-left-0 mar-right-0">
                    <div class="row mar-top-10 mar-left-0 mar-right-0 p-0">
                        <div class="col-4 d-flex flex-column pad-left-0 pad-right-7">
                            <div class="d-flex flex-row">
                                <label class="fnt-12 f500 form-label">Small</label>
                                <div mat-raised-button
                                        matTooltip="Hand/ Cabin Luggage"
                                        matTooltipPosition="above"
                                        matTooltipClass="darkBg mb-1" class="d-flex pad-top-2 mar-left-4">
                                    <img src="../../../../../../assets/icons/info.svg" class="info-tooltip-icon" />
                                </div>
                            </div>
                            <app-plus-minus (changeEvent)="update($event,'small', '')" [num]="serviceForm.controls['small'].value"></app-plus-minus>
                        </div>
                        <div class="col-4 d-flex flex-column pad-left-7 pad-right-7">
                            <div class="d-flex flex-row">
                                <label class="fnt-12 f500 form-label">Medium</label>
                                <div mat-raised-button
                                        matTooltip="Check-in Luggage"
                                        matTooltipPosition="above"
                                        matTooltipClass="darkBg mb-1" class="d-flex pad-top-2 mar-left-4">
                                    <img src="../../../../../../assets/icons/info.svg" class="info-tooltip-icon" />
                                </div>
                            </div>
                            <app-plus-minus (changeEvent)="update($event,'medium', '')" [num]="serviceForm.controls['medium'].value"></app-plus-minus>
                        </div>
                        <div class="col-4 d-flex flex-column pad-left-7 pad-right-0">
                            <div class="d-flex flex-row">
                                <label class="fnt-12 f500 form-label">Large</label>
                                <div mat-raised-button
                                        matTooltip="Oversized Luggage (Surfboards, wheelchair etc.)"
                                        matTooltipPosition="above"
                                        matTooltipClass="darkBg mb-1" class="d-flex pad-top-2 mar-left-4">
                                    <img src="../../../../../../assets/icons/info.svg" class="info-tooltip-icon" />
                                </div>
                            </div>
                            <app-plus-minus (changeEvent)="update($event,'large', '')" [num]="serviceForm.controls['large'].value" style="width: 'fit-content';">
                            </app-plus-minus>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'pad-left-32 pad-right-32': !deviceService.isMobile, 'pad-left-16 pad-right-16 flex-column': deviceService.isMobile}" class="d-flex">
                <button class="btn pointer custom-button fnt-16 f700 pad-top-14 pad-bot-14 pad-left-18 pad-right-18 border-0 secondary-founder" (click)="handleSubmit()" *ngIf="!isValid" type="submit">
                    Save & Continue
                </button>
                <button class="btn pointer custom-button justify-content-center fnt-16 f700 pad-top-14 pad-bot-14 pad-left-18 pad-right-18 border-0 primary d-flex text-nowrap" type="submit" *ngIf="isValid">
                    Save & Continue
                    <div class="small-loader mar-left-10 mt-auto mb-auto" *ngIf="isSavingServiceForm"></div>
                </button>
                <div class="d-flex align-self-center mar-right-xs-auto mar-top-xs-16" [ngClass]="{'mar-left-10': !deviceService.isMobile}" *ngIf="!isValid && formSubmited">
                    <img src="../../../../../../assets/icons/warning.svg" class="warning-icon mar-right-5" />
                    <div class="warning warning-text">
                        Please check if all fields are filled.
                    </div>
                </div>
                <div class="d-flex align-self-center mar-right-xs-auto mar-top-xs-16" [ngClass]="{'mar-left-10': !deviceService.isMobile}" *ngIf="failedToSave && failureMsg">
                    <img src="../../../../../../assets/icons/warning.svg" class="warning-icon mar-right-5" />
                    <div class="warning warning-text">
                        {{failureMsg}}
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>