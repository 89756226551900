import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-car-type-card',
  templateUrl: './car-type-card.component.html',
  styleUrls: ['./car-type-card.component.scss']
})
export class CarTypeCardComponent implements OnInit {
  @Input() vehicle;
  @Output() onChecked = new EventEmitter<any>()
  @Output() onUpdateCount = new EventEmitter<any>()
  @Input() count = 0;
  @Input() selectedVehicle;

  carImg: string = "";

  constructor() { }

  ngOnInit(): void {
    if(this.vehicle.type.toLowerCase().includes("sedan")) {
      this.carImg = "../../../../../../assets/images/sedan.svg";
    } else if(this.vehicle.type.toLowerCase().includes("mpv")) {
      this.carImg = "../../../../../../assets/images/mpv.svg";
    } else if(this.vehicle.type.toLowerCase().includes("suv")) {
      this.carImg = "../../../../../../assets/images/suv.svg";
    } else {
      this.carImg = "";
    }
  }

  onSelect(event) {
    this.count = 1;
    this.onChecked.emit(event.target.checked);
  }

  selectVehicle(event) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.count = 1;
    this.onChecked.emit(event.target.checked);
  }

  updateCount(operation) {
    if(this.selectedVehicle.name == this.vehicle.name) {
      operation == '+' ? this.count++ : this.count--;
      this.onUpdateCount.emit(this.count);
    }
  }

}
