import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from '../constants/global-variables';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AirportService {
  apiUrlBsV1: any = environment.apiUrlBsV1;
  terminals: Array<any> = JSON.parse(sessionStorage.getItem("terminals"));
  showInvalidTerminalSelected: EventEmitter<any> = new EventEmitter<any>();
  booking_id: string = "";
  objectives = {
    DEPARTURE: "departure",
    ARRIVAL: "arrival"
  };
  airportBookings: Array<any> = [];
  isAirportBookingsLoading: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private http: HttpClient,
		private GloblaConstants: GlobalConstants,
    private authService: AuthenticationService
  ) {
    this.authService.isUserLoggedIn.subscribe(() => {
      this.checkIfUserAuthenticated();
    });
    this.checkIfUserAuthenticated();
  }

  checkIfUserAuthenticated() {
    let currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : null;
    if(currentUser && !currentUser?.isTokenExpired) {
      if(localStorage.getItem("userDetails") && Object.keys(JSON.parse(localStorage.getItem("userDetails"))).length > 0) {
        let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        if(userDetails.mobile && userDetails.status == "activated") {
          this.getAllBookings();
        }
      }
    }
  }

  getAirports(searchQuery: string = ""){
    let payload;
    if(searchQuery) {
      payload = {
        search: searchQuery
      }
    }
    return this.http.get(`${this.apiUrlBsV1}gac/airport_search`, {params: payload}).pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }

  getServiceAvailability(payload) {
		return this.http.get(environment.apiUrlBsV1+'/gac/services', {params: payload}).pipe(
			map(res => res || []),
			catchError(err => throwError(err))
		)
	}

	saveServiceDetails(payload) {
		return this.http.post(environment.apiUrlBsV1+'/gac/submit_travel_details', payload).pipe(
			map(res => res || []),
			catchError(err => throwError(err))
		)
	}

  updateGacService(params) {
    return this.http.put(environment.apiUrlBsV1+'gac/update_requested_services', params )
    .pipe(
      map(res => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
    })
    )
  }

  getAllBookings() {
    this.isAirportBookingsLoading.emit(true);
    this.http.get(environment.apiUrlBsV1 + `bookings?type=GacBooking`).subscribe((response: any) => {
      if(response.data && Array.isArray(response.data) && response.data.length > 0) {
        response.data.map((booking: any) => {
          let pickUpAdrress;
          let dropAddress;
          let vehicleType;
          let transportDistance;
          let dataItem;
          if(booking.attributes?.details?.attributes?.requested_services?.data && Array.isArray(booking.attributes?.details?.attributes?.requested_services?.data) && booking.attributes?.details?.attributes?.requested_services?.data?.length > 0) {
            let requested_services = booking.attributes?.details?.attributes?.requested_services?.data[0];
            if(requested_services) {
              pickUpAdrress = requested_services.attributes.fields.find((service) => service.name == "frrom_postcode")?.value;
              dropAddress = requested_services.attributes.fields.find((service) => service.name == "to_postcode")?.value;
              transportDistance = requested_services.attributes.fields.find((service) => service.name == "distance")?.value;
              vehicleType = requested_services.attributes.fields.find((service) => service.name == "vehicle_type")?.value;
            }
          }
          dataItem = {
            'id': booking.attributes.id,
            'created_at': booking.attributes.created_at,
            'status': booking.attributes.status ? booking.attributes.status.split("_").join(" ") : "",
            'type': 'airport',
            'pick_up_address': pickUpAdrress,
            'destination_address': dropAddress,
            'transport_distance': transportDistance,
            'vehicle_type': vehicleType,
            'traveller_name': booking.attributes?.booking_details?.user_details?.name,
            'traveller_type': "adult",
            'traveller_count': booking.attributes?.details?.attributes?.user_pack?.adults,
            'journey_type': booking.attributes?.details?.attributes?.journey_details?.journey_type,
          }
          if(booking.attributes?.details?.attributes?.journey_details?.journey_type == "departure") {
              dataItem['validity'] = booking.attributes?.details?.attributes?.journey_details?.departure_date_time ? moment(booking.attributes?.details?.attributes?.journey_details?.departure_date_time).format("MMM DD YYYY") : 'Unavailable',
              dataItem['flight_no'] = booking.attributes?.details?.attributes?.journey_details?.departure_flight_no,
              dataItem['isExpired'] = moment(booking.attributes?.details?.attributes?.journey_details?.departure_date_time).isBefore(),
              dataItem['date_time'] = booking.attributes?.details?.attributes?.journey_details?.departure_date_time ? moment(booking.attributes?.details?.attributes?.journey_details?.departure_date_time).format("MMM DD, YYYY") : ""
          } else {
              dataItem['validity'] = booking.attributes?.details?.attributes?.service_date_time ? moment(booking.attributes?.details?.attributes?.service_date_time).format("MMM DD YYYY") : 'Unavailable',
              dataItem['flight_no'] = booking.attributes?.details?.attributes?.journey_details?.arrival_flight_no,
              dataItem['isExpired'] = moment(booking.attributes?.details?.attributes?.service_date_time).isBefore(),
              dataItem['date_time'] = booking.attributes?.details?.attributes?.service_date_time ? moment(booking.attributes?.details?.attributes?.service_date_time).format("MMM DD, YYYY") : ""
          }
          this.airportBookings.push(dataItem);
        })
        this.isAirportBookingsLoading.emit(false);
      }
    }, error => {
      this.isAirportBookingsLoading.emit(false);
    })
  }

  getBookingById(id) {
    return this.http
      .get(environment.apiUrlBsV1 +`bookings/${id}`)
      .toPromise();
  }

  confirmBooking(bookingId) {
    return this.http.post(environment.apiUrlBsV1+'gac/confirm_booking', {booking_id: bookingId}).pipe(
			map(res => res || []),
			catchError(err => throwError(err))
		)
  }

}

export interface airport {
  airport_code: string,
  airport_name: string,
  city: string,
  country: string,
  flag_url: string,
  id: string,
}

export interface vipServiceBookingTyp {
  requestedServices ,
  id,
  airportDetails,
  users,
  payment_details,
  journey_type: string
}
