import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { airport, AirportService } from 'src/app/common/services/airport.service';
import { DeviceService } from 'src/app/common/services/device.service';
import { ApiService } from 'src/app/shared/services/api.service';
// import { AirportService } from 'src/app/common/services/airport.service';

@Component({
  selector: 'app-search-availability',
  templateUrl: './search-availability.component.html',
  styleUrls: ['./search-availability.component.scss']
})
export class SearchAvailabilityComponent implements OnInit {

  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;
  @Output() showTravelForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateSearchAvailabilityValues: EventEmitter<any> = new EventEmitter<any>();
  @Input() isReadOnly;
  serviceObjective: string;
  objectives = this.airportService.objectives;
  terminals: Array<any> = [];
  timeType = ['am', 'pm'];
  searchForm: FormGroup = this.formBuilder.group({
    address: ['', Validators.required],
    airport: ['', Validators.required],
    date: ['', [Validators.required, Validators.minLength(2)]],
    hour: [1, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
    mins: [0, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
    time_type: [this.timeType[0], [Validators.required, Validators.minLength(2)]],
  });
  minDate: Date = new Date(new Date(new Date().getTime() + 48 * 60 * 60 * 1000));
  searchAvailabilityFormValues: any = null;
  airports: Array<any> = [];
  isValid: boolean = false;
  isLoadingAirports: boolean = false;
  pickupdDate: Date;
  selectedTerminal: any = null;
  isServiceNotAvailable: boolean = false;
  searchingAvailableServices: boolean = false;
  airportSearchQuery: string = "";
  selectedAirport: airport = null;
  showRequiredTerminalMsg: boolean = false;
  activeStep: any;
  isSubmitted: boolean = false;
  isTimeValid: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public deviceService: DeviceService,
    private airportService: AirportService,
    private toastr: ToastrService,
  ) {
    if(sessionStorage.getItem("activeStep")) {
      this.activeStep = parseInt(sessionStorage.getItem("activeStep"));
    }
    if(this.airportService.terminals) {
      this.terminals = this.airportService.terminals;
    }
    if(sessionStorage.getItem("searchAvailabilityForm")) {
      this.searchAvailabilityFormValues = JSON.parse(sessionStorage.getItem("searchAvailabilityForm"))
    }
    if(sessionStorage.getItem("selectedAirport")){
      this.selectedAirport = JSON.parse(sessionStorage.getItem("selectedAirport"));
    }
    if(sessionStorage.getItem("airports")) {
      this.airports = JSON.parse(sessionStorage.getItem("airports"));
    } else {
      this.fetchAirports();
    }
    this.airportService.showInvalidTerminalSelected.subscribe((isInvalid) => {
      if(isInvalid) {
        this.showRequiredTerminalMsg = true;
      } else {
        this.showRequiredTerminalMsg = false;
      }
    })
  }

  ngOnInit(): void {
    if(this.isReadOnly) {
      this.serviceObjective = sessionStorage.getItem("service_objective");
      setTimeout(() => {
        document.getElementById("terminal").focus();
      }, 500);
      if(sessionStorage.getItem("selectedTerminal")) {
        this.selectedTerminal = JSON.parse(sessionStorage.getItem("selectedTerminal"));
      }
    } else {
      this.serviceObjective = this.airportService.objectives.DEPARTURE;
    }
  }

  selectDeparture() {
    this.serviceObjective = this.objectives.DEPARTURE;
  }
  selectArrival() {
    this.serviceObjective = this.objectives.ARRIVAL;
  }

  formatDepartureDate(idate,itime){
    return moment(new Date(moment(idate, 'DD/MM/YYYY').format('MM/DD/YYYY') + " "+itime)).format('YYYY-MM-DD[T]HH:mm:ss.000')
  }

  formatDate(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  formatTime(time) {
    return moment(time, "h:mm a").format("hh:mm a")
  }

  selectAirport(airport) {
    sessionStorage.setItem("selectedAirport", JSON.stringify(airport));
    this.selectedAirport = airport;
    this.searchForm.controls.airport.setValue(airport.airport_name);
  }

  isPickUpDateValid() {
    if (this.searchForm.controls.hour.valid && this.searchForm.controls.mins.valid && this.searchForm.controls.time_type.valid) {
      let selectedDate = moment(this.searchForm.controls.date.value, "YYYY/MM/DD").format("DD/MM/YYYY");
      let minDateFormatted = moment(this.minDate).format("DD/MM/YYYY");
      if(moment(minDateFormatted+" "+moment(this.minDate).format("h")+":"+moment(this.minDate).format("mm")+" "+moment(this.minDate).format("a"), "DD/MM/YYYY h:mma").isBefore(moment(selectedDate+" "+this.searchForm.controls.hour.value+':'+this.searchForm.controls.mins.value+" "+this.searchForm.controls.time_type.value, 'DD/MM/YYYY h:mma'))) {
        this.isTimeValid = true;
        return true;
      } else {
        this.isTimeValid = false;
        return false;
      }
    } else {
      this.isTimeValid = false;
      return false;
    }
  }

  searchAvailability() {
    if(this.searchForm.valid && this.isPickUpDateValid()) {
      sessionStorage.setItem("service_objective", this.serviceObjective);
      sessionStorage.setItem("searchAvailabilityForm", JSON.stringify(this.searchForm.value));
      let formData = {
        service_airport_id: this.selectedAirport.id,
      }
      formData["depart_date"] = this.formatDepartureDate(this.searchForm.controls.date.value, this.searchForm.controls['hour'].value + ":" + this.searchForm.controls['mins'].value + " " + this.searchForm.controls['time_type'].value);
      this.submitTravelApi(formData);
    }
    this.isSubmitted = true;
  }

  handleFormInputChange() {
    this.isServiceNotAvailable = false;
    if(this.searchForm.valid) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  isCarTransferServiceAvailable(services) {
    let car_transfer_services = services.filter(
      function(service) {
        if(service.service_name && typeof(service.service_name) == "string") {
          return service.service_name.toLowerCase().includes("car transfer") || service.service_name.toLowerCase().includes("car tansfer")
        } else {
          return false;
        }
      }
    )
    if(car_transfer_services.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  submitTravelApi(payload) {
    if(!this.searchingAvailableServices) {
      this.searchingAvailableServices = true;
      this.airportService.getServiceAvailability(payload).subscribe((response: any) => {
        let terminals: Array<any> = [];
        if(response.data && Array.isArray(response.data) && response.data.length > 0 && response.data[0].terminals && Array.isArray(response.data[0].terminals) && response.data[0].terminals.length > 0) {
          response.data[0].terminals.map((terminal) => {
            if(terminal.services && Array.isArray(terminal.services) && terminal.services.length > 0 && this.isCarTransferServiceAvailable(terminal.services)) {
              terminals.push(terminal);
            }
          })
          if(terminals.length > 0) {
            this.airportService.terminals = terminals;
            sessionStorage.setItem("terminals", JSON.stringify(terminals));
            this.terminals = this.airportService.terminals;
            this.showTravelForm.emit();
          } else {
            this.isServiceNotAvailable = true;
          }
        } else {
          this.isServiceNotAvailable = false;
        }
        this.searchingAvailableServices = false;
      }, err => {
        this.searchingAvailableServices = false;
      })
    }
  }

  fetchAirports() {
    this.isLoadingAirports = true;
    this.airportService.getAirports().subscribe((res: any) => {
      if(res.data && Array.isArray(res.data) && res.data.length > 0) {
        this.airports = res.data;
        sessionStorage.setItem("airports", JSON.stringify(this.airports));
      }
      this.isLoadingAirports = false;
    }, err => {
      this.toastr.warning("Something went wrong when fetching airports, please try again later.");
      this.isLoadingAirports = false;
    });
  }
  
  selectTerminal(terminal) {
    this.selectedTerminal = terminal;
    this.showRequiredTerminalMsg = false;
    sessionStorage.setItem("selectedTerminal", JSON.stringify(terminal));
  }

}
